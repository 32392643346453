import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginComponent from '@/components/Auth/Auth.vue';
import PasswordResetView from '@/components/Auth/PasswordReset.vue';
import PasswordResetConfirmView from '@/components/Auth/PasswordResetConfirm.vue';
import SSOCallbackView from '@/components/Auth/SSOCallback.vue';
import RegisterComponent from '@/components/Auth/Register.vue';
import CargoLibraryComponent from '@/components/CargoLibrary/CargoLibraryView.vue';
import EquipmentViewComponent from '@/components/Equipment/index.vue';
import ContainersViewComponent from '@/components/Equipment/Containers.vue';
import SetsViewComponent from '@/components/Equipment/Sets.vue';

import NewsComponent from '@/components/News.vue';
import LoadlistsComponent from '../components/Loadlists/index.vue';
import LoadlistMainComponent from '../components/LoadlistDetail/index.vue';
import EditLoadlistComponent from '@/components/LoadlistDetail/Edit.vue';
import SetupComponent from '@/components/LoadlistDetail/Setup/index.vue';
import WorkspaceComponent from '@/components/LoadlistDetail/Workspace/index.vue';
import LoadplanComponent from '@/components/LoadlistDetail/Loadplan/index.vue';
import InteractiveComponent from '@/components/LoadlistDetail/Interactive/index.vue';
import SetBuilderComponent from '@/components/SetBuilder/index.vue';
import EditHoldComponent from '@/components/ContainerBuilder.vue';
import CompanyComponent from '@/components/CompanySettings.vue';
import UserManagementComponent from '@/components/UserManagement.vue';
import ProfileComponent from '@/components/Profile/Profile.vue';
import LoadlistSummaryComponent from '@/components/LoadlistDetail/Summary/index.vue';
import ProfileGeneralSettings from '@/components/Profile/ProfileGeneralSettings.vue';
import ProfileAccountSettings from '@/components/Profile/ProfileAccountSettings.vue';
import LoadPresetsComponent from '@/components/LoadPresets.vue';
import { Component } from 'vue-router/types/router';

Vue.use(VueRouter);

const AUTH_ROUTES: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login/',
  },
  {
    path: '/logout',
    redirect: '/login/',
  },
  {
    path: '/password_reset',
    name: 'password_reset',
    component: PasswordResetView as Component,
  },
  {
    path: '/password_reset_confirm/:uid/:token',
    name: 'password_reset_confirm',
    component: PasswordResetConfirmView as Component,
  },
  {
    path: '/sso-login',
    name: 'sso',
    redirect: '/login/',
  },
  {
    path: '/sso-callback/',
    name: 'sso-callback',
    component: SSOCallbackView as Component,
  },
  {
    path: '/register/',
    name: 'register',
    component: RegisterComponent as Component,
  },
  {
    path: '/login/',
    name: 'login',
    component: LoginComponent as Component,
    meta: { no_subscription_required: true },
  },
];

const SETTINGS_ROUTES: Array<RouteConfig> = [
  {
    path: '/company',
    name: 'company_settings',
    component: CompanyComponent as Component,
    meta: {
      title: 'Company settings',
      auth_required: true,
      no_subscription_required: false,
    },
  },
  {
    path: '/users',
    name: 'user_management',
    component: UserManagementComponent as Component,
    meta: {
      title: 'User management',
      auth_required: true,
      no_subscription_required: false,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileComponent as Component,
    meta: {
      title: 'Profile',
      auth_required: true,
      no_subscription_required: false,
    },
    children: [
      {
        path: 'general',
        name: 'general_settings',
        component: ProfileGeneralSettings as Component,
        meta: {
          title: 'Profile settings',
        },
      },
      {
        path: 'account',
        name: 'account_settings',
        component: ProfileAccountSettings as Component,
        meta: {
          title: 'Profile settings',
        },
      },
    ],
  },
];

const IN_IFRAME = !(window === window.parent);

const GENERAL_ROUTES: Array<RouteConfig> = [
  {
    path: '/loadlist/:id',
    component: LoadlistMainComponent as Component,
    meta: { auth_required: false },
    redirect: { name: 'loadlist' },
    children: [
      {
        path: 'data',
        name: 'loadlist',
        component: EditLoadlistComponent as Component,
        meta: {},
      },
      {
        path: 'setup/:settings?',
        name: 'setup',
        component: SetupComponent as Component,
        meta: {},
      },
      {
        path: 'workspace/:version?/:page?',
        name: 'workspace',
        component: WorkspaceComponent as Component,
        meta: {},
      },
      {
        path: 'loadplan/:version?/:page?',
        name: 'loadplan',
        component: LoadplanComponent as Component,
        meta: {},
      },
      {
        path: 'detail/set/:version?/:set',
        name: 'detail_set',
        component: InteractiveComponent,
        meta: {},
      },
      {
        path: 'detail/:version?/:hold/:item?',
        name: 'detail',
        component: InteractiveComponent as Component,
        meta: {},
      },
      {
        path: 'summary',
        name: 'summary',
        component: LoadlistSummaryComponent as Component,
        meta: {
          title: 'Loadlist summary',
        },
      },
    ],
  },
  {
    path: '/news/:page?',
    name: 'news',
    component: NewsComponent as Component,
    meta: {
      title: 'News',
      auth_required: true,
      no_subscription_required: false,
    },
  },

  {
    path: '/cargoes',
    name: 'cargo-library-view',
    component: CargoLibraryComponent as Component,
    meta: {
      title: 'Cargo Library',
      auth_required: true,
      no_subscription_required: false,
    },
  },
  {
    path: '/equipment',
    name: 'equipment-view',
    component: EquipmentViewComponent as Component,
    meta: {
      title: 'Equipment Library',
      auth_required: true,
      no_subscription_required: false,
    },
    redirect: { name: 'containers' },
    children: [
      {
        path: 'containers/:type?',
        name: 'containers',
        component: ContainersViewComponent as Component,
        meta: {
          title: 'Equipment Library',
        },
      },
      {
        path: 'sets/:type?',
        name: 'sets',
        component: SetsViewComponent as Component,
        meta: {
          title: 'Equipment Library',
        },
      },
    ],
  },
  {
    path: '/load-presets',
    name: 'load-presets',
    component: LoadPresetsComponent as Component,
    meta: {
      title: 'Load presets',
      auth_required: true,
      no_subscription_required: false,
    },
  },
  {
    path: '/hold/:type?/:hold_id?',
    name: 'modify_hold',
    component: EditHoldComponent as Component,
    meta: {
      title: 'Container Builder',
    },
  },
  {
    path: '/sets/:type?/:set_id?',
    name: 'modify_set',
    component: SetBuilderComponent,
    meta: {
      title: 'Set Builder',
    },
  },
  {
    path: '/loadlists/:page?',
    name: 'loadlists',
    component: LoadlistsComponent as Component,
    meta: {
      title: 'Loadplans',
      auth_required: true,
      no_subscription_required: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: IN_IFRAME ? GENERAL_ROUTES : [...AUTH_ROUTES, ...SETTINGS_ROUTES, ...GENERAL_ROUTES],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
