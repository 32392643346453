<template>
  <v-container>
    <v-card>
      <v-card-title>
        <p class="text-h6">Units</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              label="Length unit"
              v-model="length_dim"
              :items="['MM', 'CM', 'DM', 'M', 'IN']"></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Default data length unit"
              hint="When creating a new load list - automatically set the length dimension to this value"
              v-model="default_length_dim"
              :items="[null, 'MM', 'CM', 'DM', 'M', 'IN', 'FT']"
              clearable></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select label="Weight unit" v-model="weight_dim" :items="['KG', 'LB']"></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Default data weight unit "
              hint="When creating a new load list - automatically set the weight dimension to this value"
              v-model="default_weight_dim"
              :items="['KG', 'MT', 'LB']"
              clearable></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        <p class="text-h6">Graphics</p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-switch v-model="dark_mode" label="Dark mode"></v-switch>
            <v-select
              label="Default graphics view"
              v-model="default_view"
              :items="[
                { text: 'Top', value: 'top' },
                { text: 'Side', value: 'side' },
                { text: 'Front', value: 'front' },
                { text: '3D', value: '3d' },
                { text: '3d Perspective', value: '3d-perspective' },
              ]"></v-select>
            <v-select
              label="Set item color based on property"
              v-model="item_color_by_key"
              clearable
              :items="autocolor_keys"></v-select>
            <v-select
              class="mt-2"
              v-model="itemLabels"
              item-text="displayValue"
              item-value="key"
              :items="cargoLabels"
              :menu-props="{ top: false, offsetY: true }"
              multiple
              attach
              chips
              label="Cargo labels"></v-select>
            <v-checkbox
              label="Display secondary equipments as cargoes"
              v-model="show_nested_as_box"
              hint="If enabled then secondary equipments, such as pallets, will be shown as a single cargo"
              persistent-hint></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-title>
        <p class="title">Misc</p>
      </v-card-title> -->
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { UserPreferences } from '@/models/UserCompanyModel';
import itemProps, { CustomItemProperty } from '@/misc/itemProperties';

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapStores(useMiscStore),
    cargoLabels(): { key: string; displayValue: string }[] {
      return [
        { key: 'sku', displayValue: 'SKU' },
        { key: 'label', displayValue: 'Name' },
        { key: 'class_id', displayValue: 'Class' },
        { key: 'lwh', displayValue: 'LxWxH' },
        { key: 'wt', displayValue: 'Weight' },
        { key: 'shipment_id', displayValue: 'Group/Consignment' },
        { key: 'priority', displayValue: 'Priority' },
      ];
    },
    length_dim: {
      get(): string {
        return this.preferences.length_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { length_dim: value } });
      },
    },
    default_length_dim: {
      get(): string {
        return this.preferences.default_loadlist_length_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_length_dim: value },
        });
      },
    },
    weight_dim: {
      get(): string {
        return this.preferences.weight_dim;
      },
      set(value: string): void {
        this.updateMe({ preferences: { weight_dim: value } });
      },
    },
    default_weight_dim: {
      get(): string {
        return this.preferences.default_loadlist_weight_dim;
      },
      set(value: string): void {
        this.updateMe({
          preferences: { default_loadlist_weight_dim: value },
        });
      },
    },
    default_view: {
      get(): string {
        return this.preferences.default_view || '3d';
      },
      set(value: string): void {
        this.updateMe({ preferences: { default_view: value } });
      },
    },
    itemLabels: {
      get(): string[] {
        return this.preferences.item_labels;
      },
      set(value: string[]): void {
        this.updateMe({ preferences: { item_labels: value } });
      },
    },
    show_nested_as_box: {
      get(): boolean {
        return !!this.preferences.show_nested_as_box;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { show_nested_as_box: value } });
      },
    },
    item_color_by_key: {
      get(): string {
        return this.preferences.item_color_by_key || null;
      },
      set(value: boolean): void {
        this.updateMe({ preferences: { item_color_by_key: value } });
      },
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    custom_columns(): CustomItemProperty[] {
      return this.miscStore?.company_settings?.extra_columns || [];
    },
    dark_mode: {
      get(): boolean {
        return !!this.preferences.dark_mode;
      },
      set(value: boolean): void {
        this.$vuetify.theme.dark = !!value;
        this.updateMe({ preferences: { dark_mode: value } });
      },
    },

    autocolor_keys(): { text: string; value: string }[] {
      return [
        ...itemProps
          .props()
          .filter((i) => ['shipment_id', 'class_id', 'destination', 'priority'].includes(i.key))
          .map((i) => {
            return { text: i.text, value: i.key };
          }),
        ...this.custom_columns.map((i) => {
          return { text: i.name, value: i.name };
        }),
      ];
    },
  },
  methods: {
    updateMe(updateUserObject: any): void {
      this.miscStore.updateMe(updateUserObject);
    },
  },
});
</script>

<style scoped></style>
