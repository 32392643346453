import API from '@/API';
import { SceneManager } from '@/graphics/sceneManager';
import { Cargo, Hold } from '@/models/LoadlistModel';
import { SetType } from '@/models/SetsModel';
import {
  Company,
  CompanySettings,
  User,
  UserPreferences,
  UserSerializer,
} from '@/models/UserCompanyModel';
import router from '@/router';
import { defineStore } from 'pinia';
import Vue from 'vue';
import { useLoadlistStore } from './loadlistStore';
import { APIResponse } from '@/models/APIModel';

const debug = process.env.NODE_ENV !== 'production';
const RESTRICTED_USAGE_FROM_PLAN = new Map([
  ['1', false],
  ['2', true],
  ['3', false],
]);

export const useMiscStore = defineStore('misc', {
  state: () => {
    return {
      user: new User() as User,
      company: new Company() as Company,
      cargoes: [] as Cargo[],
      isLoading: false,
      systemHolds: [] as Hold[],
      userHolds: [] as Hold[],
      systemSets: [] as SetType[],
      userSets: [] as SetType[],
      error_message: null as string | null,
      showAccessCodeRequiredModal: false,
    };
  },
  getters: {
    // user: (state) => state.user,
    // company: (state) => state.company,
    company_settings: (state) => state.company.settings || {},
    user_preferences: (state) => state.user.preferences || {},
    is_authenticated: (state) => !!state.user.id,
    lite_version: (state) => RESTRICTED_USAGE_FROM_PLAN.get(state.company.plan),
    trial_version: (state) => state.company.plan === '1',
    preferences(): CompanySettings {
      // console.log({ ...this.company_settings, ...this.user_preferences })
      return { ...this.company_settings, ...this.user_preferences };
    },
    length_dim(): string {
      return this.preferences.length_dim || 'CM';
    },
    weight_dim(): string {
      return this.preferences.weight_dim || 'KG';
    },
    miscStoreIsLoading: (state) => state.isLoading,
    sets: (state) => [...state.userSets, ...state.systemSets],
    holds: (state) => [...state.userHolds, ...state.systemHolds],
  },
  actions: {
    setUser(data: UserSerializer): void {
      if (data) {
        this.user = new User(data);

        SceneManager.setUserDefaults(this.preferences);

        if (data?.preferences?.dark_mode) router.app.$vuetify.theme.dark = true;
        Vue.prototype.$populateChatUser(
          data.id,
          data.email || undefined,
          data.company?.name || undefined
        );
        if (!debug)
          window.bugsnagClient.user = {
            id: this.user.id,
          };
      } else {
        this.user = new User();
      }
    },

    setUserPreference(data: { key: string; value: string | string[] }): void {
      Vue.set(this.user.preferences, data.key, data.value);
    },
    getMe(): Promise<undefined> {
      return new Promise((resolve, reject) => {
        API.getMe()
          .then((response) => {
            this.company = new Company(response.data.company);
            this.setUser(response.data);
            SceneManager.setUserDefaults(this.preferences);

            resolve(undefined);
            useLoadlistStore().syncGroups();
            this.getAllUserEquipment();
            this.downloadCargoes();
          })
          .catch(() => {
            reject();
          });
      });
    },
    updateMe(data: { preferences: UserPreferences }): Promise<undefined> {
      return new Promise((resolve, reject) => {
        API.updateUser({ ...data, id: this.user.id })
          .then((response) => {
            this.setUser(response.data);
            resolve(undefined);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    loginWithCredentials(username: string, password?: string): Promise<APIResponse> {
      return new Promise((resolve, reject) => {
        API.auth(username, password)
          .then(() => {
            this.getMe()
              .then(() => {
                resolve(undefined);
              })
              .catch(() => {
                reject();
              });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    logout(redirectRoute: string | null): void {
      this.setUser(null);
      this.company = null;
      API.logout();
      useLoadlistStore().clearLoadlistData();
      this.cargoes = [];
      this.userHolds = [];
      this.userSets = [];
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login', query: { redirect: redirectRoute } });
      }
    },

    updateCompanySettings(data: CompanySettings): Promise<undefined> {
      return new Promise((resolve, reject) => {
        API.updateCompanySettings(this.company.id, data)
          .then((response) => {
            this.company.settings = response.data;
            resolve(undefined);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getUserHolds(): Promise<unknown> {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        API.getUserHolds()
          .then((holds) => {
            this.userHolds = holds.data;
            Object.freeze(this.userHolds);
            this.isLoading = false;
            resolve(undefined);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getUserSets(): Promise<unknown> {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        API.getUserSets()
          .then((sets) => {
            this.userSets = sets.data;
            Object.freeze(this.userSets);
            this.isLoading = false;
            resolve(undefined);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getAllUserEquipment(): Promise<unknown> {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        API.getUserAppData()
          .then((response) => {
            this.userHolds = response.data.holds;
            this.userSets = response.data.sets;
            Object.freeze(this.userHolds);
            Object.freeze(this.userSets);
            this.isLoading = false;
            resolve(undefined);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },

    getAllSystemEquipment(): Promise<unknown> {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        API.getDefaultAppData()
          .then((response) => {
            this.systemHolds = response.data.holds;
            this.systemSets = response.data.sets;
            Object.freeze(this.systemHolds);
            Object.freeze(this.systemSets);
            this.isLoading = false;
            resolve(undefined);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },
    downloadCargoes(): Promise<unknown> {
      this.isLoading = true;

      return new Promise((resolve, reject) => {
        API.getCargoes()
          .then((response) => {
            this.cargoes = response.data;
            this.isLoading = false;

            resolve(undefined);
          })
          .catch((error) => {
            this.isLoading = false;
            reject(error);
          });
      });
    },
  },
});
