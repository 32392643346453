<template>
  <v-dialog :value="visible" @input="(val) => $emit('update:visible', val)" scrollable>
    <v-card height="90vh">
      <v-card-title>
        <v-toolbar flat>
          <v-btn icon @click="$emit('update:visible', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <template v-slot:extension>
            <v-tabs v-model="active_tab" fixed-tabs>
              <v-tab v-for="tab in tabs" :key="tab.title">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-tabs-items class="ma-3" v-model="active_tab">
          <v-tab-item v-for="tab in tabs" :key="tab.key">
            <v-card flat class="mb-4" v-if="tab.key === 'primary_settings'">
              <v-card-text>
                <main-settings v-model="internal_settings" :base_type="base_type" />
              </v-card-text>
            </v-card>
            <v-card flat class="mb-4" v-if="tab.key === 'segregation_table'">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card outlined flat>
                      <v-card-title>Segregation table </v-card-title>
                      <v-card-actions>
                        <segregation-table-editor
                          :input="internal_settings.segregation_table"
                          @update="updateSegragationTable"></segregation-table-editor>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <custom-rules-editor
              v-if="tab.key === 'custom_rules'"
              :key="ruleRender"
              :holds="holds"
              :items="items"
              :rules="internal_settings.rules"
              :typeName="typeName"
              :class_ids="class_ids"
              @addRule="addRule"
              @removeRule="removeRule" />
            <v-card flat class="mb-4" v-if="tab.key === 'secondary'">
              <v-card-text>
                <v-alert outlined type="info">
                  These settings affect the loading of the secondary equipment only
                </v-alert>
                <!-- <v-btn
                  @click="internal_settings.secondary_settings = null"
                  v-if="internal_settings.secondary_settings"
                  >Use same as for primary</v-btn
                > -->
                <v-btn
                  color="success"
                  @click="addSecondarySettings"
                  v-if="!internal_settings.secondary_settings">
                  <v-icon>mdi-plus</v-icon>Add settings
                </v-btn>
                <main-settings
                  v-if="internal_settings.secondary_settings"
                  v-model="internal_settings.secondary_settings"
                  :hide_sections="['performance', 'cargo']"
                  is_secondary
                  always_open
                  base_type="PALL" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import SegregationTableEditor from './SegregationTableEditor.vue';
import ShippingFactorInput from './ShippingFactorInput.vue';
import OverhangInput from './OverhangInput.vue';

import { HoldData, ListType, LoadRule, HoldInputItem } from '@/models/LoadlistModel';
import { CalculationSettings } from '@/models/CalculationModel';
import CustomRulesEditor from './CustomRulesEditor/index.vue';
import LengthInputField from '@/components/Custom/LengthInputField.vue';
import AdvancedSection from './AdvancedSection.vue';
import MainSettings from './MainSettings.vue';

export const DEFAULT_SETTINGS = {
  timeout: 2,
  load_style: null,
  max_containers: null,
  use_containers_in_sets: false,
  error_margin: 0.0,
  allow_heavy_on_light_item: false,
  // one_side_overhang: false,
  separate_shipments: false,
  group_shipments: false,
  single_container_shipment: false,
  rules: null,
  prioritize_cog: false,
  allowed_overhang: 0.0,
  max_stack_height: null,
  segregation_table: null,
  load_whole_shipments_only: false,
  shipping_factor: null,
  max_layers_only_per_sku: false,
  single_stacking: false,
  routing: false,
  strict_priorities: false,
} as CalculationSettings;

const DEFAULT_SECONDARY_SETTINGS = {
  load_style: 'bottom_up',
} as CalculationSettings;

export default Vue.extend({
  components: {
    SegregationTableEditor,
    ShippingFactorInput,
    OverhangInput,
    CustomRulesEditor,
    LengthInputField,
    AdvancedSection,
    MainSettings,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object as PropType<CalculationSettings>,
      default: () => {
        return { ...DEFAULT_SETTINGS };
      },
    },
    title: {
      type: String,
      default: 'Load settings',
    },
    base_type: {
      type: String as PropType<ListType>,
      default: 'SEA',
    },
    holds: {
      type: Array as PropType<HoldData[]>,
      default: () => [] as HoldData[],
    },
    items: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
  },
  computed: {
    tabs(): { key: string; title: string; show: boolean }[] {
      return [
        {
          key: 'primary_settings',
          title: 'Primary',
          show: true,
        },
        {
          key: 'secondary',
          title: 'Secondary',
          show: true,
        },
        {
          key: 'custom_rules',
          title: 'Custom Rules',
          show: true,
        },
        {
          key: 'segregation_table',
          title: 'Segregation table',
          show: true,
        },
      ].filter((i) => i.show);
    },
    visibleTabs(): any {
      return this.tabs.filter((t) => t.show);
    },
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    loading_styles() {
      return [
        { text: 'Auto', value: null },
        { text: 'Minimize used length', value: 'minimize_length' },
        { text: 'Load bottom up', value: 'bottom_up' },
      ];
    },
    class_ids(): string[] {
      if (this.internal_settings?.segregation_table) {
        return Object.keys(this.internal_settings?.segregation_table);
      }
      return [];
    },
  },
  watch: {
    settings: {
      handler(a: CalculationSettings): void {
        let load_style = undefined;
        if (a.load_style === undefined) {
          if (a.load_from_bottom) load_style = 'bottom_up';
          else if (a.load_direction == 'shortwall') load_style = 'minimize_length';
        }
        this.internal_settings = {
          ...this.internal_settings,
          load_style,
          ...JSON.parse(JSON.stringify(a)),
        };
      },
      immediate: true,
    },
    visible(a: boolean): void {
      // Only emit when closing the modal
      if (!a) this.$emit('update', JSON.parse(JSON.stringify(this.internal_settings)));
    },
  },
  data() {
    return {
      internal_settings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS)) as CalculationSettings,
      ruleRender: 0,
      active_tab: 0,
      advancedCargo: [],
      advancedStacking: [],
    };
  },
  methods: {
    updateSegragationTable(segregation_table: any): void {
      this.$set(this.internal_settings, 'segregation_table', segregation_table);
    },
    addRule(rule: LoadRule): void {
      if (!this.internal_settings.rules) this.internal_settings.rules = [];
      this.internal_settings.rules.push(rule);
    },
    removeRule(index: number): void {
      this.internal_settings.rules.splice(index, 1);
      this.ruleRender++;
      if (!this.internal_settings.rules.length) this.internal_settings.rules = null;
    },
    addSecondarySettings(): void {
      this.$set(this.internal_settings, 'secondary_settings', {
        ...DEFAULT_SETTINGS,
        ...DEFAULT_SECONDARY_SETTINGS,
      });
    },
  },
});
</script>

<style>
.v-slider--horizontal {
  margin: 0;
}
</style>
