<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat outlined class="px-2 pb-2 mb-4" v-if="!hide_sections.includes('cargo')">
          <v-card-title>Cargo</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-label>Error margin [%]</v-label>
                <v-slider
                  class="py-2"
                  v-model="internal_settings.error_margin"
                  hint="Make the cargoes smaller or larger. A value of 10 will make each cargo 10% larger. A value of -10 will make each cargo 10% smaller."
                  :persistent-hint="!!internal_settings.error_margin"
                  thumb-label="always"
                  :thumb-size="24"
                  :thumb-color="internal_settings.error_margin ? 'primary' : 'grey'"
                  track-color="light-grey"
                  :track-fill-color="internal_settings.error_margin ? 'primary' : 'grey'"
                  :max="50"
                  :min="-50"
                  step="1"
                  ticks="always"
                  tick-size="0"></v-slider>
              </v-col>
              <v-col class="my-auto">
                <v-img
                  class="mx-auto"
                  width="200"
                  :cover="false"
                  src="@/assets/svg/explainers/error_margin.svg" />
              </v-col>
            </v-row>
          </v-card-text>
          <advanced-section :always_open="always_open">
            <shipping-factor-input
              label="Shipping factor"
              v-model="internal_settings.shipping_factor"></shipping-factor-input>
          </advanced-section>
        </v-card>

        <v-card flat outlined class="px-2 pb-2" v-if="!hide_sections.includes('stacking')">
          <v-card-title>Stacking</v-card-title>
          <v-card-text>
            <overhang-input
              label="Allowed overhang"
              v-model="internal_settings.allowed_overhang"></overhang-input>
            <v-row>
              <v-col>
                <v-switch
                  v-model="internal_settings.allow_heavy_on_light_item"
                  label="Allow heavy on light"
                  hint="Heavier cargoes can be loaded on top of lighter cargoes"
                  persistent-hint>
                </v-switch>
              </v-col>
              <v-col>
                <v-img
                  class="mx-auto"
                  width="200"
                  :cover="false"
                  src="@/assets/svg/explainers/allow_heavier_on_lighter.svg" />
              </v-col>
            </v-row>
          </v-card-text>
          <advanced-section :always_open="always_open">
            <v-row>
              <v-col>
                <v-label>Max stacking height</v-label>
                <length-input-field v-model="internal_settings.max_stack_height">
                </length-input-field>
              </v-col>
              <v-col>
                <v-img
                  class="mx-auto"
                  width="200"
                  :cover="false"
                  src="@/assets/svg/explainers/max_stack_height.svg" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                  v-model="internal_settings.max_layers_only_per_sku"
                  label="Max Layers per SKU"
                  hint="Cargo max layer option is only applicable on items with same SKU"
                  persistent-hint>
                </v-switch>
              </v-col>
              <v-col>
                <v-img
                  class="mx-auto"
                  width="200"
                  :cover="false"
                  src="@/assets/svg/explainers/max_layer_for_sku_only.svg" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                  v-model="internal_settings.single_stacking"
                  label="Single cargo support"
                  hint="Each cargo can only be supported by max one cargo below"
                  persistent-hint>
                </v-switch>
              </v-col>
              <v-col>
                <v-img
                  class="mx-auto"
                  width="200"
                  :cover="false"
                  src="@/assets/svg/explainers/supported_max_one.svg" />
              </v-col>
            </v-row>
          </advanced-section>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined flat class="mb-4 px-2 pb-2" v-if="!hide_sections.includes('performance')">
          <v-card-title>Performance</v-card-title>
          <v-card-text>
            <v-label>Max calculation time (s)</v-label>
            <v-row class="mt-2">
              <v-col>
                <v-slider
                  class="py-2"
                  v-model="internal_settings.timeout"
                  hint="A higher timeout (usually) means a better solution for complex lists. Max 10 seconds"
                  thumb-label="always"
                  :thumb-size="24"
                  :max="10"
                  :min="0"
                  step="1"
                  ticks="always"
                  tick-size="0"></v-slider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          flat
          outlined
          class="mb-4 pb-2 px-2"
          v-if="!hide_sections.includes('loading_style')">
          <v-card-title>Loading style</v-card-title>

          <v-card-text>
            <v-text-field
              v-if="!is_secondary"
              :label="`Max number of ${typeName}s to be used`"
              :value="internal_settings.max_containers"
              @input="internal_settings.max_containers = parseInt($event) || null"
              min="0"
              step="1"
              type="number" />

            <v-switch
              v-if="['ROAD'].includes(base_type)"
              label="Enable Routing"
              hint="Uses Item Destination to set the load order. Overrides priority if enabled"
              persistent-hint
              v-model="internal_settings.routing" />

            <v-switch
              label="Strict priorities"
              hint="A lower priority cargo is never allowed to be loaded before a higher priority, even if the higher priority can't be loaded at all"
              persistent-hint
              v-model="internal_settings.strict_priorities" />
          </v-card-text>
          <advanced-section :always_open="always_open">
            <v-select
              v-model="internal_settings.load_style"
              label="Loading style"
              :items="loading_styles"
              hint="How cargoes should be loaded. Auto (default) uses container characteristics to apply the most suitable"
              persistent-hint />

            <v-switch
              label="Prioritize Center Of Gravity"
              hint="If enabled then the center of gravity should be within 10% from the center"
              persistent-hint
              v-model="internal_settings.prioritize_cog" />

            <v-switch
              v-if="!is_secondary"
              :label="`Load ${typeName}s in sets`"
              v-model="internal_settings.use_containers_in_sets"
              :hint="`If this is true, the 1st ${typeName} type will be loaded, then the 2nd, then 1st again etc... Useful when using Road trains`"
              persistent-hint />
          </advanced-section>
        </v-card>

        <v-card flat outlined class="px-2 pb-2" v-if="!hide_sections.includes('consignments')">
          <v-card-title>Consignments / Groups</v-card-title>
          <v-card-text style="margin-bottom: -16px">
            <!-- <v-form> -->
            <v-switch v-model="internal_settings.separate_shipments">
              <template slot="label">
                <v-row align="center">
                  <v-col> Consignments cannot be mixed </v-col>
                  <v-col>
                    <v-img width="220" :cover="false" src="@/assets/svg/explainers/not_mixed.svg" />
                  </v-col>
                </v-row>
              </template>
            </v-switch>
            <v-switch v-model="internal_settings.group_shipments">
              <template slot="label">
                <v-row align="center">
                  <v-col> Group consignments together </v-col>
                  <v-col>
                    <v-img width="220" src="@/assets/svg/explainers/group_shipments.svg" />
                  </v-col>
                </v-row>
              </template>
            </v-switch>
          </v-card-text>
          <advanced-section :always_open="always_open">
            <v-switch v-model="internal_settings.single_container_shipment">
              <template slot="label">
                <v-row align="center">
                  <v-col> Use at most a single container per consignment </v-col>
                  <v-col>
                    <v-img width="220" src="@/assets/svg/explainers/single_container.svg" />
                  </v-col>
                </v-row>
              </template>
            </v-switch>
            <v-switch v-model="internal_settings.load_whole_shipments_only">
              <template slot="label">
                <v-row align="center">
                  <v-col> Entire consignments must be loaded or none </v-col>
                  <v-col>
                    <v-img width="220" src="@/assets/svg/explainers/all_or_none.svg" />
                  </v-col>
                </v-row>
              </template>
            </v-switch>
          </advanced-section>
        </v-card>

        <!-- </v-form> -->
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { CalculationSettings } from '@/models/CalculationModel';
import { ListType } from '@/models/LoadlistModel';
import Vue, { PropType } from 'vue';
import AdvancedSection from './AdvancedSection.vue';
import ShippingFactorInput from './ShippingFactorInput.vue';
import OverhangInput from './OverhangInput.vue';
export default Vue.extend({
  components: { AdvancedSection, ShippingFactorInput, OverhangInput },
  name: 'main-settings',
  data: function () {
    return {
      internal_settings: {} as CalculationSettings,
    };
  },
  props: {
    value: Object as PropType<CalculationSettings>,
    base_type: {
      type: String as PropType<ListType>,
      default: 'SEA',
    },
    hide_sections: {
      type: Array as PropType<string[]>,
      default: (): string[] => {
        return [];
      },
    },
    always_open: Boolean,
    is_secondary: Boolean,
  },
  watch: {
    internal_settings: {
      handler: function (val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
  mounted(): void {
    this.internal_settings = this.value;
  },
  computed: {
    typeName(): string {
      return this.$typeNames(this.base_type);
    },
    loading_styles() {
      return [
        { text: 'Auto', value: null },
        { text: 'Minimize used length', value: 'minimize_length' },
        { text: 'Load bottom up', value: 'bottom_up' },
      ];
    },
  },
  methods: {},
});
</script>
