<template>
  <v-dialog v-model="showModal" scrollable height="90vh" persistent>
    <v-card v-if="show" height="90vh">
      <v-card-title class="headline">Excel parser</v-card-title>
      <v-card-text class="d-flex flex-column">
        <div class="mb-2" v-for="f in files" :key="f.name">{{ f.name }}</div>
        <v-alert type="error" v-if="showNeedAssistanceAlert">
          We were unable to read your excel sheet.
          <br />
          Try using our
          <a
            :href="'/static/templates/load_list_template_v1.7.xlsx'"
            class="white--text font-weight-bold text-decoration-underline">
            template
          </a>
          or go back to the data sheet and select the appropriate columns and rows in Excel, Copy
          <code>Ctrl+C</code>, then Paste
          <code>Ctrl+V</code>
          directly in the sheet.
        </v-alert>

        <v-alert type="info" v-else>
          Check if the following is correct, and modify manually if not. Also, remember to set the
          right
          <strong>length</strong>
          and
          <strong>weight dimensions</strong>.
          <br />
          <strong>Length</strong>,
          <strong>Width</strong>
          and
          <strong>Height</strong>
          columns needs to be provided before you can import your items.
          <p class="mb-0">
            While we are able to read most xlsx files, you can always use
            <a
              :href="'/static/templates/load_list_template_v1.7.xlsx'"
              class="white--text font-weight-bold text-decoration-underline">
              this template
            </a>
            to import your data.
          </p>
        </v-alert>

        <div>
          <v-row v-if="dimensions">
            <v-col sm="12" md="2" class="pb-0">
              <v-select
                label="Length unit"
                v-model="dimensions.lengthDim"
                @input="setDirty()"
                :items="['MM', 'CM', 'DM', 'M', 'IN', 'FT']"></v-select>
            </v-col>
            <v-col sm="12" md="2" class="pb-0">
              <v-select
                label="Weight dimension"
                v-model="dimensions.weightDim"
                @input="setDirty()"
                :items="['KG', 'LB']"></v-select>
            </v-col>
            <v-col sm="12" md="2" class="pb-0">
              <v-text-field
                label="Sheet No:"
                placeholder="E.g. 1"
                v-model="sheetNumber"
                type="number"
                @input="
                  readExcel();
                  setDirty();
                "
                :rules="[(value) => parseInt(value) > 0 || 'Enter a valid number']"
                min="1"></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox label="Hide empty columns" v-model="hideEmptyColumns" light></v-checkbox>
            </v-col>
            <v-col>
              <div class="flex-column d-flex align-end">
                <v-btn
                  class="mt-4"
                  @click="showTemplates = true"
                  :disabled="!templates.length"
                  elevation="0"
                  light
                  :outlined="autoTemplateSelected || dirty"
                  :color="autoTemplateSelected || dirty ? 'default' : 'primary'">
                  <v-icon>mdi-database</v-icon>
                  Templates
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <p class="my-0">
            <strong>Quantity:</strong>
            {{ totalQuantity }} pcs
          </p>
        </div>
        <div class="spinner-container" v-if="isLoading">
          <v-progress-circular
            :size="100"
            :width="7"
            color="primary"
            indeterminate></v-progress-circular>
        </div>

        <v-data-table
          v-else
          :items="itemRowsTable"
          :items-per-page="25"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: itemsPerPageOptions,
          }"
          item-key="uid"
          class="elevation-1 mt-4">
          <template slot="header">
            <tr class="header-row">
              <th>
                <div style="width: 68px"></div>
              </th>
              <template v-for="(column, index, key) in columns">
                <th v-if="visibleColumns.includes(column.index)">
                  <div class="d-flex">
                    <v-menu
                      offset-y
                      top
                      :close-on-content-click="false"
                      style="background-color: white">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="my-auto"
                          style="margin-right: -8px"
                          small
                          icon
                          v-bind="attrs"
                          v-on="on">
                          <v-icon small :color="column.filter ? 'warning' : ''">mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <div class="px-2 py-2" style="background-color: white">
                        <v-text-field
                          label="Equals"
                          clearable
                          v-model="column.filter"></v-text-field>
                      </div>
                    </v-menu>
                    <v-autocomplete
                      :ref="'autocomplete-' + index"
                      style="min-width: 140px"
                      class="mx-2"
                      placeholder="Specify column"
                      return-object
                      item-text="text"
                      multiple
                      persistent-hint
                      density="compact"
                      :value="column.properties"
                      :items="headerOptions"
                      :menu-props="{ top: true, offsetY: true }"
                      @change="updateColumn($event, index)"
                      @blur="removeSearchFromAutomcomplete(index)"></v-autocomplete>
                  </div>
                </th>
              </template>
            </tr>
          </template>

          <template slot="item" slot-scope="props">
            <tr>
              <td>
                <v-btn icon>
                  <v-icon color="error" @click="removeRow(props.item.index)"
                    >mdi-minus-circle</v-icon
                  >
                </v-btn>
              </td>
              <template v-for="(entry, index) in props.item.values">
                <td v-if="visibleColumns.includes(index)">
                  <div class="pl-4">
                    <v-tooltip bottom v-if="entry.error">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="warning">mdi-alert</v-icon>
                      </template>
                      <span>Invalid value for selected column</span>
                    </v-tooltip>
                    <span>{{ entry.value }}</span>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="newList">
        <v-btn @click.stop="showModal = false" :loading="isLoading" outlined>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-select
          :items="usedColumns"
          v-model="splitIntoListsByProperty"
          clearable
          return-object
          item-value="key"
          label="Split items into new loadlists based on value in column">
        </v-select>
        <v-btn
          v-if="splitIntoListsByProperty"
          color="primary"
          class="ml-4"
          @click.stop="showCreatBulkLoadlistDialog = true"
          >Create</v-btn
        >
        <v-btn v-else color="primary" class="ml-4" @click.stop="showCreatLoadlistDialog = true"
          >Create</v-btn
        >
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn @click.stop="showModal = false" :loading="isLoading" outlined>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mr-4"
          v-bind:label="'Replace current data with parsed data'"
          v-model="excelParseReplaceCurrent"
          light></v-checkbox>
        <v-btn color="primary" @click.stop="done" :disabled="!isImportEnabled">Import</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showSaveOrImport" max-width="600">
      <v-card>
        <v-card-title>Save changes</v-card-title>
        <v-card-text>
          You have modified the intepretation of this file. Do you want to save your modifications
          as an
          <em>import template</em> for use with similar files?
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click.stop="
              newTemplate = { name: '', description: '' };
              showSaveOrImport = false;
              showNewTemplate = true;
            ">
            <v-icon>mdi-floppy</v-icon>
            Save & Import
          </v-btn>
          <v-spacer />
          <v-btn
            @click.stop="
              dirty = false;
              done();
            "
            color="primary">
            Just Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewTemplate" max-width="600">
      <v-card>
        <v-card-title>New Template</v-card-title>

        <v-card-text>
          Choose a name
          <v-form
            class="mt-4"
            v-model="validTemplateForm"
            ref="templateForm"
            lazy-validation
            @submit.prevent="saveAndDone()">
            <v-text-field
              label="Template Name"
              v-model="newTemplate.name"
              :rules="[
                (v) => !!v || 'A name is required',
                (v) =>
                  !(miscStore.company_settings?.import_templates || []).find((t) => t.name === v) ||
                  'This name is already taken',
              ]" />
            <v-textarea
              label="Template description"
              hint="What type of file it is, where it comes from, or how it's different from the automatic interpretation"
              v-model="newTemplate.description" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.stop="showNewTemplate = false" outlined>Cancel</v-btn>
          <v-spacer />
          <v-btn @click.stop="saveAndDone()" color="success" :loading="isLoading">
            <v-icon>mdi-floppy</v-icon>
            Save & Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTemplates" max-width="600">
      <v-card>
        <v-card-title>Import Templates</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item two-line v-if="!!autoTemplate">
              <v-list-item-content>
                <v-list-item-title :class="autoTemplateSelected ? 'font-weight-bold' : ''">
                  <v-icon v-if="autoTemplateSelected">mdi-arrow-right</v-icon>
                  {{ autoTemplate.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ autoTemplate.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-large
                      v-on="on"
                      @click.stop="
                        applyTemplate(autoTemplate);
                        showTemplates = false;
                      ">
                      <v-icon :color="autoTemplateSelected ? '' : 'success'">mdi-import</v-icon>
                    </v-btn>
                  </template>
                  <span>Use this template</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="template in templates" v-bind:key="template.name" two-line>
              <v-list-item-content>
                <v-list-item-title
                  :class="template.name === appliedTemplate ? 'font-weight-bold' : ''">
                  <v-icon v-if="template.name === appliedTemplate">mdi-arrow-right</v-icon>
                  {{ template.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ template.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="flex-row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-large
                      v-on="on"
                      @click.stop="setDefaultTemplate(template.name)"
                      :disabled="!user.is_editor">
                      <v-icon color="primary" v-if="template.default">mdi-star</v-icon>
                      <v-icon color="primary" v-else>mdi-star-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Set as default</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="!user.is_editor"
                      icon
                      x-large
                      v-on="on"
                      @click.stop="
                        templateToRemove = template.name;
                        showRemoveTemplate = true;
                      ">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove template</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      x-large
                      v-on="on"
                      @click.stop="
                        useTemplate(template.name);
                        showTemplates = false;
                      ">
                      <v-icon :color="template.name === appliedTemplate ? '' : 'success'"
                        >mdi-import</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Use this template</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click.stop="showTemplates = false" outlined>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRemoveTemplate" width="400">
      <v-card>
        <v-card-title>
          <span class="text-h5">Remove template?</span>
        </v-card-title>
        <v-card-text>
          Do you really want to remove the import template
          <b>"{{ templateToRemove }}"</b>?
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showRemoveTemplate = false" outlined>Cancel</v-btn>
          <v-spacer />
          <v-btn class="mr-2" color="error" @click="deleteTemplate(templateToRemove)">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCreatBulkLoadlistDialog" scrollable v-if="splitIntoListsByProperty">
      <v-card>
        <v-card-title>
          <span class="text-h5">Bulk import</span>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-alert type="info" outlined
            >Create one new loadlist for each value based on {{ splitIntoListsByProperty.text }}
          </v-alert>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newLoadlistNameAppendix"
                label="Loadlist name appendix"></v-text-field>
            </v-col>
            <v-col>
              <v-select
                id="listTypeSelect"
                required
                v-bind:items="$list_types"
                v-model="newLoadlistType"
                label="List type:"
                :rules="[(v) => !!v || 'List type is required']"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="newLoadlistGroup"
                item-value="id"
                item-text="name"
                v-bind:items="groups"
                required
                label="Project (Optional)"></v-select>
            </v-col>
            <v-col>
              <v-dialog ref="dialog" v-model="showEtdDialog" persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="newLoadlistEtd"
                    label="ETD"
                    readonly
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="newLoadlistEtd" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showEtdDialog = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="showEtdDialog = false">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-simple-table height="600px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Rows</th>
                  <th>New loadlist name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, key) in splittedItems" :key="key">
                  <td>{{ key }}</td>
                  <td>{{ val.length }}</td>
                  <td>{{ key }}{{ newLoadlistNameAppendix }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showCreatBulkLoadlistDialog = false" outlined>Cancel</v-btn>
          <v-spacer />
          <v-btn
            class="mr-2"
            color="primary"
            :disabled="Object.keys(splittedItems).length == 0"
            @click="createLists"
            >{{ `Create ${Object.keys(splittedItems).length} loadlists` }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <new-list-component
      v-if="showCreatLoadlistDialog"
      :visible="showCreatLoadlistDialog"
      @close="showCreatLoadlistDialog = false"
      :loadlist_data="items"
      :length_dim="dimensions.lengthDim"
      :weight_dim="dimensions.weightDim"
      @created="$router.push({ name: 'loadlist', params: { id: $event } })"></new-list-component>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue';
import ExcelService from '@/services/excelService';
import { Hold, HoldInputItem, ListType, Loadlist, LoadlistGroup } from '@/models/LoadlistModel';
import { CellValue, CellFormulaValue, Workbook } from 'exceljs';
import ItemProperties, { ItemProperty, ParsingProperty } from '@/misc/itemProperties';
import { ExcelImportData, ItemRow } from '@/models/InputDataModel';
import { useMiscStore } from '@/stores/miscStore';
import { useLoadlistStore } from '@/stores/loadlistStore';

import {
  ColumnIndexForProperty,
  ColumnInfo,
  DisplayItemRow,
  ImportTemplate,
} from '@/models/ExcelImport';
import NewListComponent from './NewList.vue';
import { ParseResult } from 'papaparse';
import { mapStores } from 'pinia';
import API from '@/API';
import { User } from '@/models/UserCompanyModel';
import { getSerializerError } from '@/misc/errorUtils';
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        templateForm: any;
      };
    }
  >
).extend({
  name: 'excel-parser',
  components: {
    NewListComponent,
  },
  data() {
    return {
      itemsPerPageOptions: [25, 50, 100, -1],
      isLoading: false,
      sheetNumber: 1,
      showNeedAssistanceAlert: false,
      columns: [] as ColumnInfo[],
      hideEmptyColumns: true,
      importData: undefined as ExcelImportData,
      dimensions: {
        weightDim: 'KG',
        lengthDim: 'M',
      },
      splitIntoListsByProperty: null as ItemProperty,
      excelParseReplaceCurrent: true,
      truthyAnswers: ['y', 'yes', 'true'],
      falsyAnswers: ['n', 'no', 'false'],
      // Import Template variables
      dirty: false,
      showTemplates: false,
      showSaveOrImport: false,
      showNewTemplate: false,
      showRemoveTemplate: false,
      showCreatLoadlistDialog: false,
      showCreatBulkLoadlistDialog: false,
      showEtdDialog: false,
      newLoadlistNameAppendix: `-${new Date().toISOString().split('T')[0]}`,
      newLoadlistType: (localStorage.getItem('cplLastLoadlistType') || 'SEA') as ListType,
      newLoadlistGroup: null,
      newLoadlistEtd: null,
      newTemplate: { name: '', description: '' },
      validTemplateForm: false,
      templateToRemove: '',
      appliedTemplate: undefined,
      removedRows: [],
      autoTemplate: undefined as ImportTemplate,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<HoldInputItem[]>,
      default: () => [] as HoldInputItem[],
    },
    files: {
      type: Array as PropType<File[]>,
      default: () => [] as File[],
    },
    weightDim: {
      type: String,
      default: 'KG',
    },
    lengthDim: {
      type: String,
      default: 'M',
    },
    newList: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    weightDim: {
      handler(newVal, oldVal) {
        this.dimensions.weightDim = newVal;
      },
      immediate: true,
    },
    lengthDim: {
      handler(newVal, oldVal) {
        this.dimensions.lengthDim = newVal;
      },
      immediate: true,
    },
    show: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.readExcel();
        } else {
          this.importData = undefined;
          this.columns = [];
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    isImportEnabled(): boolean {
      const propertyKeys = this.columns.flatMap((column) =>
        column.properties.flatMap((property) => property.key)
      );
      return propertyKeys.includes('l') && propertyKeys.includes('w') && propertyKeys.includes('h');
    },
    showModal: {
      get(): boolean {
        return this.show;
      },
      set(val: boolean): void {
        this.$emit('update:show', !!val);
      },
    },
    holdsLibrary(): Hold[] {
      return this.miscStore.holds;
    },
    templates(): ImportTemplate[] {
      return this.miscStore.company_settings?.import_templates || [];
    },
    headerOptions(): ItemProperty[] {
      return [
        ...ItemProperties.props()
          .filter((property) => !property.additional && property.type !== 'object')
          .concat(ItemProperties.additionalParsingProps())
          .concat(
            (this.miscStore.company_settings?.extra_columns || []).map((v) => ({
              key: v.name,
              text: v.name,
              desc: v.desc,
              width: 100,
              input: 'text',
              type: 'string',
              custom: true,
            }))
          )
          .sort((a, b) => (a.text > b.text ? 1 : -1)),
      ];
    },
    /// ItemRows without removedRows
    cleanedRows(): ItemRow[] {
      return (this.importData?.itemRows || []).filter(
        (v, i) => !(this.removedRows || []).includes(i)
      );
    },
    filteredRows(): ItemRow[] {
      // hides rows if filters are applied

      const columnsWithFilters = this.columns.filter((headers) => headers.filter);

      return this.cleanedRows.filter((itemRow) => {
        return (
          columnsWithFilters.length === 0 ||
          columnsWithFilters.every((c) =>
            itemRow.values[c.index]?.toString().toLowerCase().includes(c.filter.toLowerCase())
          )
        );
      });

      return [];
    },
    autoTemplateSelected(): boolean {
      return this.autoTemplate?.name === this.appliedTemplate || this.appliedTemplate === undefined;
    },
    visibleColumns(): number[] {
      // filters out ids of empty columns if hideEmptyColumns is true
      if (this.hideEmptyColumns)
        return this.columns
          .filter(
            (header) =>
              header.properties.length > 0 ||
              !this.itemRowsTable.every(
                (itemRow) =>
                  itemRow.values[header.index].value === undefined ||
                  itemRow.values[header.index].value === ''
              )
          )
          .map((itemRow) => itemRow.index);
      return this.columns.map((column, index) => index);
    },
    usedColumns(): ItemProperty[] {
      return this.headerOptions.filter((i) => {
        return ['shipment_id', 'class_id'].includes(i.key) || i.custom;
      });
    },
    getDefaultRow(): HoldInputItem {
      return this.miscStore.preferences.default_input_row
        ? this.miscStore.preferences.default_input_row
        : ({} as HoldInputItem);
    },
    items(): HoldInputItem[] {
      return this.filteredRows.map((itemRow) => {
        let holdItem: any = JSON.parse(JSON.stringify(this.getDefaultRow));
        this.columns.forEach((column) => {
          // Normal properties
          column.properties
            .map((p) => p as ParsingProperty)
            .filter((p) => !p.mapWith)
            .forEach((property) => {
              const importedValue = itemRow.values[column.index];
              const parsedValue = this.parseValue(importedValue, property.type);
              const propertyValue = this.getCustomPropertyValue(parsedValue, property.key);
              holdItem[property.key] = propertyValue;
            });
        });
        this.columns.forEach((column) => {
          // Parsing properties
          column.properties
            .map((p) => p as ParsingProperty)
            .filter((p) => p.mapWith)
            .forEach((property) => {
              const importedValue = itemRow.values[column.index];
              const parsedValue = this.parseValue(importedValue, property.type);
              const sval = importedValue?.toString().trim() || '';
              if (sval.length) {
                property.mapWith(holdItem, sval, parsedValue);
              }
            });
        });
        return holdItem;
      });
    },
    splittedItems(): { [key: string]: HoldInputItem[] } {
      if (!this.splitIntoListsByProperty) return {};
      return this.items.reduce((accumulator: { [key: string]: HoldInputItem[] }, item) => {
        // Get the key (property value) to use for splitting
        const key = item[this.splitIntoListsByProperty.key as keyof HoldInputItem];

        if (key) {
          // If the accumulator doesn't have an array for this key yet, create it
          if (!accumulator[key]) {
            accumulator[key] = [];
          }

          // Add the current item to the array for its key
          accumulator[key].push(item);
        }

        return accumulator;
      }, {});
    },

    itemRowsTable(): DisplayItemRow[] {
      return this.filteredRows.map((itemRow) => ({
        index: itemRow.index,
        values: itemRow.values.flatMap((value, index) => {
          const itemPropertiesOnColumn = this.columns[index].properties;
          const error =
            itemPropertiesOnColumn.some((itemProperty) => {
              const parsedValue = this.parseValue(value, itemProperty.type);
              const propertyValue = this.getCustomPropertyValue(parsedValue, itemProperty.key);
              return value !== undefined && propertyValue === undefined;
            }) || false;
          return { value, error };
        }),
      }));
    },

    totalQuantity(): number {
      return this.items.reduce((acc, curr) => acc + (curr.qty || 1), 0);
    },
    groups(): LoadlistGroup[] {
      return [
        { name: '(Default)', id: null, private: true, data: {} },
        ...this.loadlistStore.loadlistGroups,
      ];
    },
    defaultTemplate(): ImportTemplate {
      return this.templates.find((t) => t.default);
    },
    user(): User {
      return this.miscStore.user;
    },
  },
  filters: {
    yesno: function (value: boolean): string {
      return value ? 'Yes' : 'No';
    },
  },
  methods: {
    setDirty(): void {
      this.dirty = true;
      this.appliedTemplate = undefined;
    },
    removeRow(index: number): void {
      this.setDirty();
      this.removedRows = [...this.removedRows, index];
    },
    calculateHeaders(data: ExcelImportData): ColumnInfo[] {
      // gets a list of item properties with associated column position
      const itemPropertiesWithColumnIndex = this.headerOptions
        .map((itemProperty) => ({
          itemProperty,
          index: data.options.get(itemProperty.key)?.mapping,
        }))
        .sort((a, b) => a.index - b.index);

      const numberOfColumns = data.itemRows.length > 0 ? data.itemRows[0].values.length : 0;
      const presentItemPropertiesWithPositions = itemPropertiesWithColumnIndex.filter(
        (itemPropertyWithPosition) => itemPropertyWithPosition.index !== undefined
      );

      return Array.from({ length: numberOfColumns }, (_, index) => {
        const propertyItemsForColumn = presentItemPropertiesWithPositions
          .filter((ip) => ip.index === index)
          .map((ip) => ip.itemProperty);
        return { index, filter: '', properties: propertyItemsForColumn };
      });
    },
    updateColumn(propertiesForColumn: ItemProperty[], columnIndex: number) {
      this.setDirty();
      this.removeSearchFromAutomcomplete(columnIndex);

      // removes the changed properties from all columns before adding them
      const columns = this.columns.map((column) => {
        column.properties = column.properties.filter(
          (property) =>
            !propertiesForColumn.some((propertyForColumn) => propertyForColumn.key === property.key)
        );
        return column;
      });
      columns[columnIndex].properties = propertiesForColumn;
      this.columns = columns;
    },
    removeSearchFromAutomcomplete(index: number): void {
      const key = `autocomplete-${index}`;
      const autocompleteRefs = this.$refs[key] as any;
      if (autocompleteRefs?.length === 1) {
        autocompleteRefs[0].lazySearch = '';
      }
    },
    readExcel: function (): void {
      const loadExcelJS = () => import('exceljs');
      this.isLoading = true;
      this.showNeedAssistanceAlert = false;
      loadExcelJS().then((exceljs) => {
        const workbook = new exceljs.Workbook();

        for (const file of this.files) {
          new Promise((resolve, reject) => {
            if (file.type === 'text/csv') {
              const loadPapa = () => import('papaparse');
              loadPapa().then((Papa) => {
                Papa.parse(file, {
                  complete: (results: ParseResult<string>) => {
                    const worksheet = workbook.addWorksheet('1');
                    worksheet.addRows(results.data);
                    resolve(workbook);
                  },
                  error: (e) => {
                    reject(e);
                  },
                });
              });
            } else {
              new Response(file)
                .arrayBuffer()
                .then((data) => {
                  return workbook.xlsx.load(data);
                })
                .then((workbook: Workbook) => {
                  resolve(workbook);
                })
                .catch(() => {
                  reject();
                });
            }
          })
            .then((workbook: Workbook) => {
              const excelService = new ExcelService();
              const data = excelService.parseLoadlist(workbook, Number(this.sheetNumber));
              const columns = this.calculateHeaders(data);
              if (this.importData && this.columns?.length == columns.length) {
                this.importData.itemRows = [...this.importData.itemRows, ...data.itemRows];
              } else {
                this.importData = data;
                this.columns = columns;

                // back-up the interpretation
                this.autoTemplate = JSON.parse(
                  JSON.stringify({
                    name: 'Auto-interpretation',
                    description: 'Let Cargo-Planner interpret the file automatically',
                    columns: this.columns,
                    sheetNumber: this.sheetNumber,
                    lengthDim: this.dimensions.lengthDim,
                    weightDim: this.dimensions.weightDim,
                    skipRows: [],
                  })
                ) as ImportTemplate;
              }

              if (data.dimensions) {
                this.dimensions = data.dimensions;
              }
              if (this.defaultTemplate) {
                this.useTemplate(this.defaultTemplate.name);
              }
            })
            .catch((e) => {
              if (e) console.error(e);
              this.showNeedAssistanceAlert = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
          // At this point we want to get a workbook
        }
      });
    },
    getIdsForLoadInContainers(loadInString: string | undefined): number[] | undefined {
      if (loadInString) {
        const containerNames = loadInString.trim().split(',');
        const containerIds = this.holdsLibrary
          .filter((hold) =>
            containerNames.some(
              (containerName) => hold.name.toLowerCase() === containerName.trim().toLowerCase()
            )
          )
          .map((h) => h.id);
        return containerIds.length > 0 ? containerIds : undefined;
      }
      return undefined;
    },
    parseNumber(value: string | undefined): number | undefined {
      if (isNaN(Number(value))) {
        // replacing a comma with a dot
        if (/^\d+,\d+$/.test(value)) {
          return this.parseNumber(value.replace(',', '.'));
        }
        return undefined;
      }
      return Number(value);
    },
    isValueInList(value: string, variants: string[]) {
      return variants.some((ans) => ans.toLowerCase() === value.toLowerCase());
    },
    parseBoolean(value: string): boolean | undefined {
      if (this.isValueInList(value, this.truthyAnswers)) {
        return true;
      } else if (this.isValueInList(value, this.falsyAnswers)) {
        return false;
      }
      return undefined;
    },
    parseHoldSelect(value: string): number[] {
      return this.getIdsForLoadInContainers(value);
    },
    parseValue(value: CellValue, type: string) {
      if (value === undefined) {
        return undefined;
      }
      const valueString =
        typeof value === 'object'
          ? (value as CellFormulaValue).result.toString()
          : value.toString();
      switch (type) {
        case 'string':
        case 'list':
          return valueString;
        case 'float':
        case 'integer':
          return this.parseNumber(valueString);
        case 'bool':
          return this.parseBoolean(valueString);
        default:
          return undefined;
      }
    },
    getCustomPropertyValue(
      parsedValue: string | number | boolean | undefined,
      propertyKey: string
    ) {
      if (parsedValue === undefined) {
        return undefined;
      } else if (propertyKey === 'geometry') {
        const lowerCasedValue = (parsedValue as string).toLowerCase().replaceAll(' ', '_');
        const geometryAvailableValues = ItemProperties.props().find(
          (prop) => prop.key === propertyKey
        ).values;
        const geometryValue = geometryAvailableValues.find(
          (value) => value.key === lowerCasedValue
        )?.key;
        return geometryValue;
      } else if (propertyKey === 'allowed_containers') {
        return this.parseHoldSelect(parsedValue as string);
      }
      return parsedValue;
    },

    useTemplate(name: string): void {
      const template = JSON.parse(JSON.stringify(this.templates.find((t) => t.name === name)));
      if (!template) return;
      this.applyTemplate(template);
    },
    applyTemplate(template: ImportTemplate): void {
      this.appliedTemplate = template.name;
      // Don't just replace this.columns with template.columns, since this file might have more/fewer columns than the template
      this.columns = this.columns.map((c, i) => {
        if (i < template.columns.length) {
          return template.columns[i];
        } else {
          return { ...c, properties: [] } as ColumnInfo;
        }
      });
      this.columns = this.columns.map((c) => ({
        ...c,
        properties: c.properties.map((p) => this.headerOptions.find((o) => o.key == p.key) || p),
      }));
      this.sheetNumber = template.sheetNumber;
      this.dimensions.lengthDim = template.lengthDim;
      this.dimensions.weightDim = template.weightDim;
      this.removedRows = template.skipRows || [];

      this.dirty = false;
    },
    deleteTemplate(name: string): void {
      const index = this.templates.findIndex((t) => t.name === name);
      if (index < 0) {
        this.miscStore.error_message = "Couldn't find selected template";
        return;
      }
      let import_templates = JSON.parse(
        JSON.stringify(this.miscStore.company_settings?.import_templates || [])
      ) as ImportTemplate[];
      import_templates.splice(index, 1);
      this.miscStore
        .updateCompanySettings({ ...(this.miscStore.company_settings || {}), import_templates })
        .catch((e) => console.error(e))
        .finally(() => {
          this.showRemoveTemplate = false;
          this.templateToRemove = '';
        });
    },
    setDefaultTemplate(name: string): void {
      let import_templates = (JSON.parse(JSON.stringify(this.templates)) as ImportTemplate[]).map(
        (t) => {
          if (t.name == name || t.default) {
            t.default = !t.default;
          }
          return t;
        }
      );
      this.miscStore.updateCompanySettings({
        ...(this.miscStore.company_settings || {}),
        import_templates,
      });
    },
    saveTemplates(import_templates: ImportTemplate[]): void {
      this.miscStore
        .updateCompanySettings({
          ...(this.miscStore.company_settings || {}),
          import_templates,
        })
        .then(() => {
          this.dirty = false;
          this.done();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    saveAndDone(): void {
      if (!this.$refs.templateForm.validate()) {
        return;
      }

      this.isLoading = true;
      // Create the template
      let template = JSON.parse(
        JSON.stringify({
          name: this.newTemplate.name,
          description: this.newTemplate.description,
          columns: this.columns,
          sheetNumber: this.sheetNumber,
          lengthDim: this.dimensions.lengthDim,
          weightDim: this.dimensions.weightDim,
          skipRows: this.removedRows,
        })
      ) as ImportTemplate;
      // Save the template to company settings
      let import_templates = JSON.parse(JSON.stringify(this.templates));
      import_templates.push(template);
      this.saveTemplates(import_templates);
    },
    done(): void {
      if (this.dirty && this.user.is_editor) {
        // Modal to save the changes as a template
        this.showSaveOrImport = true;
        return;
      }
      this.$emit('input', [...(this.excelParseReplaceCurrent ? [] : this.value), ...this.items]);
      if (this.dimensions) {
        this.$emit('updateWeightDim', this.dimensions.weightDim);
        this.$emit('updateLengthDim', this.dimensions.lengthDim);
      }
      this.showModal = false;
    },
    createLists(): void {
      const lists = Object.keys(this.splittedItems).map((key) => {
        const items = this.splittedItems[key];

        return {
          name: `${key}${this.newLoadlistNameAppendix}`,
          data: items,
          etd: this.newLoadlistEtd,
          list_type: this.newLoadlistType,
          length_dim: this.dimensions.lengthDim,
          weight_dim: this.dimensions.weightDim,
          group: this.newLoadlistGroup,
        } as Loadlist;
      });

      API.bulkCreateLoadlist(lists)
        .then((data) => {
          this.showCreatBulkLoadlistDialog = false;
          this.showModal = false;
          localStorage.setItem('cplLastLoadlistType', this.newLoadlistType);
          this.$emit('loadlistsCreated');
        })
        .catch((error) => {
          if (error?.response?.data)
            this.miscStore.error_message = getSerializerError(error.response.data);
        });
    },
  },
});
</script>

<style scoped>
th {
  user-select: none;
}
.v-data-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}
>>> .v-data-table__wrapper {
  height: 100%;
  position: relative;
}
>>> .v-data-table > .v-data-table__wrapper > table {
  display: block;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.header-row {
  position: sticky;
  top: 0;
  z-index: 20;
  background: white;
}
tr th:first-child,
tr td:first-child {
  position: sticky;
  min-width: 50px;
  left: 0;
  z-index: 10;
  background: white;
}
>>> .v-data-table > .v-data-table__wrapper > table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 12px;
}
>>> .v-data-table > .v-data-table__wrapper > table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
>>> .v-select__selections > input {
  min-width: 0 !important;
}
.spinner-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: pointer;
}
</style>
