<template>
  <div>
    <v-container fluid>
      <v-toolbar
        flat
        class="pl-0 top-toolbar"
        style="position: relative; z-index: 2; background: none">
        <div
          :style="usageSettings.hideProjects ? '' : 'width:400px; min-width:400px'"
          class="d-flex">
          <div class="flex-grow-1">
            <span
              style="cursor: pointer"
              @click="usageSettings.hideProjects = !usageSettings.hideProjects">
              <v-btn text :color="!usageSettings.hideProjects ? '' : 'primary'">
                <v-icon class="mr-2">mdi-folder-multiple-outline</v-icon>
                <span class="text-button">Projects</span>
              </v-btn>
            </span>
          </div>

          <v-btn
            icon
            v-if="!readonly && !usageSettings.hideProjects && user.is_editor"
            @click="$refs.projects.newProject()">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </div>

        <v-divider vertical class="ml-2" inset v-show="usageSettings.hideProjects" />
        <div>
          <v-tabs v-model="usageSettings.loadlistView" class="ml-2">
            <v-tab v-for="tab in tabs" :key="tab.value"
              ><v-icon>{{ tab.icon }}</v-icon
              >{{ tab.value }}</v-tab
            >
          </v-tabs>
        </div>

        <!-- <v-spacer /> -->
        <v-text-field
          append-icon="mdi-magnify"
          v-model="searchString"
          class="mx-4 white rounded-pill"
          clearable
          outlined
          dense
          hide-details
          label="Search"
          placeholder="Search for a loadlist. To search for a cargo - prefix with 'cargo:', eg 'cargo:abc123'"></v-text-field>
        <v-spacer />
        <v-divider vertical class="mr-4" />

        <v-file-input
          v-model="dropFiles"
          hide-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
          @change="showParseExcelModal = true"
          style="flex: none"
          multiple></v-file-input>

        <v-btn v-if="!readonly" @click="showAddNewLoadlistModal = true" color="success">
          <v-icon>mdi-plus-circle</v-icon> New
        </v-btn>
      </v-toolbar>
      <v-divider class="mb-4" style="position: relative; z-index: 2" />
      <div class="d-flex">
        <v-slide-x-transition hide-on-leave>
          <projects
            v-if="!usageSettings.hideProjects"
            ref="projects"
            :show="!usageSettings.hideProjects"
            :groupId="activeGroupId"
            :groups="loadlist_groups"
            @activeGroupChange="updateActiveGroup($event)"
            @loadlistAddedToGroup="refreshLoadlists()"
            :readonly="readonly || !user.is_editor"
            class="mr-4"
            style="min-width: 450px"></projects>
        </v-slide-x-transition>
        <div class="flex-grow-1">
          <v-tabs-items v-model="usageSettings.loadlistView">
            <v-tab-item v-for="tab in tabs" :key="tab.value">
              <loadlistsTable
                ref="listTable"
                v-if="tab.value === 'list'"
                :groupId="activeGroupId"
                :forceUpdate="forceUpdate"
                :readonly="readonly"
                :searchString="search"
                @selectedLoadlist="$emit('selectedLoadlist', $event)"
                @generateExamples="generateExamples"></loadlistsTable>
              <loadlists-calendar
                v-if="tab.value === 'calendar'"
                :groupId="activeGroupId"
                :groups="loadlist_groups"
                :readonly="readonly"
                :searchString="search"
                @selectedLoadlist="$emit('selectedLoadlist', $event)"></loadlists-calendar>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-container>

    <v-dialog v-model="showNoEmailModal" scrollable width="600">
      <v-card>
        <v-card-title class="text-h5">No email is set!</v-card-title>
        <v-card-text> You need to attach an email address to this account. </v-card-text>
        <v-card-actions>
          <v-btn block class="primary" @click.stop="$router.push({ name: 'account_settings' })"
            >Update now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <new-list-modal
      :visible="showAddNewLoadlistModal"
      :group="activeGroupId"
      @close="showAddNewLoadlistModal = false"
      @created="$router.push({ name: 'loadlist', params: { id: $event } })"></new-list-modal>
    <packlist-reader
      :show.sync="showParseExcelModal"
      :files="dropFiles"
      newList
      @loadlistsCreated="refreshLoadlists"></packlist-reader>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue';
import loadlistsTable from './TableView.vue';
import loadlistsCalendar from './CalendarView.vue';
import projects from './Projects.vue';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { LoadlistGroup } from '@/models/LoadlistModel';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { User } from '@/models/UserCompanyModel';
import NewListModal from '@/components/Modals/NewList.vue';
import { UsageSettings } from '../../stores/usageSettings';
import { getExamples } from '@/misc/exampleLists';
import API from '@/API';
import PacklistReader from '../Modals/PacklistReader.vue';
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        listTable: InstanceType<typeof loadlistsTable>;
        projects: InstanceType<typeof projects>;
      };
    }
  >
).extend({
  name: 'loadlists',
  components: {
    loadlistsTable,
    loadlistsCalendar,
    projects,
    NewListModal,
    PacklistReader,
  },
  data() {
    return {
      usageSettings: UsageSettings.fetch(),
      activeGroupId: null as number | null,
      showNoEmailModal: false,
      forceUpdate: 0,
      searchFocused: false,
      searchString: '',
      search: '',
      lastEditTimeout: null as number | null,
      showAddNewLoadlistModal: false,
      showParseExcelModal: false,
      dropFiles: [],
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    searchString: function (val) {
      if (this.lastEditTimeout) clearTimeout(this.lastEditTimeout);
      if (!val) {
        this.search = val;
      } else {
        // Waiting to update the search result to avoid many queries when searching.
        const f: TimerHandler = () => {
          this.search = val;
        };
        this.lastEditTimeout = setTimeout(f, 500);
      }
    },
    showParseExcelModal: function (val) {
      if (!val) this.dropFiles = [];
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    user(): User {
      return this.miscStore.user;
    },
    isAuthenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    tabs(): any[] {
      return [
        { value: 'list', icon: 'mdi-format-list-bulleted' },
        { value: 'calendar', icon: 'mdi-calendar' },
      ];
    },
    loadlist_groups(): LoadlistGroup[] {
      return this.loadlistStore.loadlistGroups;
    },
  },
  created(): void {
    if (this.$route.query.group) {
      this.activeGroupId = parseInt(String(this.$route.query.group)) || null;
    }
    if (this.$route.query.search) {
      this.search = String(this.$route.query.search);
    }
  },
  mounted(): void {
    if (this.isAuthenticated && !this.user.email) this.showNoEmailModal = true;
  },
  methods: {
    generateExamples() {
      const groups = this.loadlistStore.loadlistGroups;
      const exampleGroup = groups.find((g) => g.name === 'Examples');
      if (exampleGroup) {
        // We already have an examples group. Switch to that instead
        this.activeGroupId = exampleGroup.id;
        return;
      }
      API.createLoadlistGroup({ name: 'Examples' })
        .then((e) => {
          const group = e.data.id;
          getExamples()
            .then((lists) =>
              Promise.all(
                lists.map((loadlist) => {
                  loadlist.group = group;
                  return API.createLoadlist(loadlist as any);
                })
              )
            )
            .finally(() => {
              // "refresh"
              this.loadlistStore.syncGroups().then(() => {
                this.activeGroupId = group;
                this.forceUpdate++;
              });
            })
            .catch((e) => console.error('creating loadlists', e));
        })
        .catch((e) => console.log('creating loadlist group', e));
    },
    updateActiveGroup(groupId: number): void {
      this.activeGroupId = groupId;
    },
    refreshLoadlists(): void {
      this.forceUpdate++;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td {
  cursor: pointer;
}
.top-toolbar >>> .v-toolbar__content {
  padding: 0px !important;
}
</style>
