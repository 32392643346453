<template>
  <v-container>
    <v-row>
      <v-col cols="10" offset="1" sm="6" offset-sm="3">
        <v-card>
          <v-card-title>
            <img
              style="width: 100%; max-width: 300px; margin-left: auto; margin-right: auto"
              src="../../assets/svg/logo_black.svg" />
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login" id="login-form" autocomplete="on" v-if="!is_locked">
              <transition name="fade" mode="out-in">
                <div :key="step">
                  <v-text-field
                    prepend-icon="mdi-account"
                    v-show="isFirstStep"
                    label="Username or email"
                    v-model="username"
                    placeholder=" "
                    type="text"
                    autocomplete="email"></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    v-show="!isFirstStep"
                    :label="`Enter password for ${username}`"
                    v-model="password"
                    placeholder=" "
                    type="password"
                    autocomplete="current-password"></v-text-field>
                </div>
              </transition>
            </v-form>
            <v-alert outlined type="error" v-if="errors.length">
              <span v-for="e in errors" :key="e">{{ e }}</span>
              <p class="mt-2">
                <a @click="changeUser"> Change user</a>
              </p>
              <p>
                <router-link to="/password_reset/"> Forgot your password?</router-link>
              </p>
            </v-alert>
            <v-alert outlined type="info" v-if="redirecting"> Redirecting... </v-alert>
            <v-alert v-if="magicLinkSent" outlined type="info">
              <p>
                Please check your email now (also in your spam folder). The link is valid for 5
                minutes
              </p>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="isLoading"
              :disabled="disabledForm"
              form="login-form"
              >{{ isFirstStep ? 'Next' : 'Login' }}</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <!-- <p>
              Login without password?
              <router-link to="/magic-link/">Click here</router-link>
            </p> -->
            <p v-if="!isFirstStep && isValidEmail">
              or
              <a @click="sendMagicLink">receive an email with login link</a>
            </p>

            <p>
              No account?
              <router-link to="/register/">Sign up here</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import API from '@/API';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';

export default Vue.extend({
  name: 'login',
  data() {
    return {
      isLoading: false,
      step: 1,
      username: '',
      password: '',
      errors: [],
      is_locked: false,
      redirecting: false,
      magicLinkSent: false,
    };
  },
  computed: {
    ...mapStores(useMiscStore),
    disabledForm(): boolean {
      if (this.is_locked) return true;
      if (!this.isFirstStep) return !this.password || this.isLoading;
      return !this.username || this.isLoading;
    },
    is_authenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    isValidEmail(): boolean {
      // Simple HTML5-like pattern for email validation
      const pattern =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.username);
    },
    isFirstStep(): boolean {
      return this.step === 1;
    },
  },
  watch: {
    is_authenticated: {
      handler: function (authenticated: boolean): void {
        if (authenticated) {
          const redirect = this.$route.query.redirect
            ? (this.$route.query.redirect as string)
            : '/news';
          this.$router.push(redirect);
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeUser() {
      this.username = '';
      this.password = '';
      this.prevStep();
      this.is_locked = false;
      this.errors = [];
    },
    nextStep(): void {
      this.step = 2;
    },
    prevStep(): void {
      this.step = 1;
    },
    login(): void {
      if (this.isFirstStep && !this.isValidEmail) {
        this.nextStep();
        return;
      }
      this.isLoading = true;

      this.miscStore
        .loginWithCredentials(this.username, this.password)
        .then(() => {
          this.nextStep();
        })
        .catch((e) => {
          if (e?.response) {
            if (e.response.status === 303 && e.response.data.redirect_url) {
              this.redirecting = true;
              window.location.replace(e.response.data.redirect_url);
            } else {
              const errors = Object.values(e.response.data);
              if (!this.isFirstStep && !errors.length)
                errors.push('Could not login with provided details');
              this.errors = errors;
              if (e.response.status === 403) this.is_locked = true;
              this.nextStep();
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sendMagicLink(): void {
      this.isLoading = true;

      API.magicLink({
        email: this.username.trim(),
      })
        .then(() => {
          this.magicLinkSent = true;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
