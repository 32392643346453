import { Header, InputDataTable, ValueRow } from '@/models/InputDataModel';
import { HoldData, HoldItem } from '@/models/LoadlistModel';
import { Parser } from 'expr-eval';

export default {
  loadTableToJson(tableElement: HTMLTableElement, unloaded = false): InputDataTable {
    const headers = [] as Header[][];
    const dataRows = [] as ValueRow[];
    const rows = Array.from(tableElement.rows);
    let totalRow = {} as ValueRow;

    rows
      .filter((row) => row.cells.length > 0 && row.cells[0].tagName == 'TH')
      .forEach((row) => {
        const header = Array.from(row.cells).map((cell) => {
          const colspan = Number(cell.getAttribute('colspan'));
          return {
            value: cell.innerText.trim(),
            colspan: isNaN(colspan) ? 1 : colspan || 1,
          };
        });
        headers.push(header);
      });

    if (headers.length > 0) {
      rows
        .filter((row) => row.cells.length > 0 && row.cells[0].tagName == 'TD')
        .forEach((row) => {
          const values = Array.from(row.cells).map((cell) => {
            const value = cell.innerText.trim();
            if (value === '' || value === '<!---->') {
              return null;
            } else {
              return Number.isNaN(Number(value)) ? value : Number(value);
            }
          });
          const rowData = { isFirstChild: false, values };
          if (row.parentElement.firstChild === row) {
            rowData.isFirstChild = true;
          }
          if (rowData.values[1] == null) {
            totalRow = rowData;
          } else {
            dataRows.push(rowData);
          }
        });
    }
    return {
      headerRows: headers,
      valuesRows: dataRows,
      totalRow: totalRow,
      unloaded,
    };
  },
  calculateFormula(formula: any, item: HoldItem, hold: HoldData): number {
    try {
      return Parser.evaluate(formula, {
        ItemLength: item.L,
        ItemWidth: item.W,
        ItemHeight: item.H,
        ItemWeight: item.WT,
        ItemArea: (item.bb.max.x - item.bb.min.x) * (item.bb.max.y - item.bb.min.y),
        ItemCenterX: item.bb.min.x + (item.bb.max.x - item.bb.min.x) / 2.0,
        ItemCenterY: item.bb.min.y + (item.bb.max.y - item.bb.min.y) / 2.0,
        ItemMinZ: item.bb.min.z,
        ItemQty: item.qty,
        ContainerL: hold.L,
        ContainerW: hold.W,
      });
    } catch (e) {
      return null;
    }
  },
};
