import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c('p',{staticClass:"text-h6"},[_vm._v("Units")])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":"Length unit","items":['MM', 'CM', 'DM', 'M', 'IN']},model:{value:(_vm.length_dim),callback:function ($$v) {_vm.length_dim=$$v},expression:"length_dim"}})],1),_c(VCol,[_c(VSelect,{attrs:{"label":"Default data length unit","hint":"When creating a new load list - automatically set the length dimension to this value","items":[null, 'MM', 'CM', 'DM', 'M', 'IN', 'FT'],"clearable":""},model:{value:(_vm.default_length_dim),callback:function ($$v) {_vm.default_length_dim=$$v},expression:"default_length_dim"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":"Weight unit","items":['KG', 'LB']},model:{value:(_vm.weight_dim),callback:function ($$v) {_vm.weight_dim=$$v},expression:"weight_dim"}})],1),_c(VCol,[_c(VSelect,{attrs:{"label":"Default data weight unit ","hint":"When creating a new load list - automatically set the weight dimension to this value","items":['KG', 'MT', 'LB'],"clearable":""},model:{value:(_vm.default_weight_dim),callback:function ($$v) {_vm.default_weight_dim=$$v},expression:"default_weight_dim"}})],1)],1)],1),_c(VCardTitle,[_c('p',{staticClass:"text-h6"},[_vm._v("Graphics")])]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VSwitch,{attrs:{"label":"Dark mode"},model:{value:(_vm.dark_mode),callback:function ($$v) {_vm.dark_mode=$$v},expression:"dark_mode"}}),_c(VSelect,{attrs:{"label":"Default graphics view","items":[
              { text: 'Top', value: 'top' },
              { text: 'Side', value: 'side' },
              { text: 'Front', value: 'front' },
              { text: '3D', value: '3d' },
              { text: '3d Perspective', value: '3d-perspective' },
            ]},model:{value:(_vm.default_view),callback:function ($$v) {_vm.default_view=$$v},expression:"default_view"}}),_c(VSelect,{attrs:{"label":"Set item color based on property","clearable":"","items":_vm.autocolor_keys},model:{value:(_vm.item_color_by_key),callback:function ($$v) {_vm.item_color_by_key=$$v},expression:"item_color_by_key"}}),_c(VSelect,{staticClass:"mt-2",attrs:{"item-text":"displayValue","item-value":"key","items":_vm.cargoLabels,"menu-props":{ top: false, offsetY: true },"multiple":"","attach":"","chips":"","label":"Cargo labels"},model:{value:(_vm.itemLabels),callback:function ($$v) {_vm.itemLabels=$$v},expression:"itemLabels"}}),_c(VCheckbox,{attrs:{"label":"Display secondary equipments as cargoes","hint":"If enabled then secondary equipments, such as pallets, will be shown as a single cargo","persistent-hint":""},model:{value:(_vm.show_nested_as_box),callback:function ($$v) {_vm.show_nested_as_box=$$v},expression:"show_nested_as_box"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }