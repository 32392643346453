<template>
  <v-card>
    <v-alert type="info" outlined>Set default values for data input in 1. Data view</v-alert>
    <v-row>
      <v-col v-for="i in defaultsSelection" :key="i.key" cols="6">
        <v-checkbox
          v-if="i.input === 'checkbox'"
          :label="i.text"
          v-model="item[i.key]"></v-checkbox>
        <v-select
          v-else-if="i.input === 'select'"
          :items="i.values"
          item-value="key"
          :label="i.text"
          v-model="item[i.key]">
        </v-select>
        <orientation-picker
          v-else-if="i.input === 'orientations_picker'"
          :item="{}"
          v-model="item[i.key]"></orientation-picker>
        <v-text-field
          v-else-if="i.type === 'string'"
          :label="i.text"
          type="text"
          v-model.number="item[i.key]"></v-text-field>
        <v-text-field
          v-else
          :label="i.text"
          type="number"
          v-model.number="item[i.key]"></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import ItemProperties, { ItemProperty } from '@/misc/itemProperties';
import { HoldInputItem } from '@/models/LoadlistModel';
import OrientationPicker from '@/components/Custom/OrientationPicker.vue';

export default Vue.extend({
  components: {
    OrientationPicker,
  },
  props: {
    value: {
      type: Object as PropType<HoldInputItem | null>,
      default: () => {
        return { qty: 1 } as HoldInputItem;
      },
    },
  },
  data() {
    return {
      item: {} as HoldInputItem,
      useCustomValues: false,
    };
  },
  watch: {
    value: {
      handler: function (a: HoldInputItem) {
        this.item = JSON.parse(JSON.stringify(a));
      },
      immediate: true,
    },
    useCustomValues: function (a) {
      if (!a) this.item = {} as HoldInputItem;
    },
  },
  computed: {
    defaultsSelection(): ItemProperty[] {
      return ItemProperties.props()
        .filter((i) => !i.additional && !i.readOnly)
        .filter(
          (i) =>
            ![
              'sku',
              'l',
              'w',
              'h',
              'wt',
              'color',
              'allowed_containers',
              'label',
              'destination',
              'free_space',
              'class_id',
              'priority',
              'bundling',
            ].includes(i.key)
        )
        .sort((a, b) => {
          if (a.input < b.input) return -1;
          if (a.input > b.input) return 1;
          return 0;
        });
    },
  },
});
</script>

<style></style>
