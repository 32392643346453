<template>
  <div>
    <slot name="set-scene"></slot>
    <div class="my-2" style="page-break-before: always">
      <p class="container-title text-h4">
        <span v-if="container.__indices.end > container.__indices.start">
          <b>{{ container.__indices.start + 1 }}</b> to
          {{ container.__indices.end + 1 }}
        </span>
        <span v-else>
          {{ container.__indices.start + 1 }}
        </span>
        : {{ container.name }}
      </p>
      <v-text-field
        v-if="showQuotations"
        class="quotation-input"
        label="Quotation"
        type="number"
        :prefix="currencySymbol"
        persistent-hint
        :value="holdQuotations"
        @input="$emit('updateHoldsQuotation', $event)"></v-text-field>
      <scene-component
        enableReuse
        @renderDone="sceneRendered = true"
        :key="String(showRuler)"
        :canvas-width="$vuetify.breakpoint.xsOnly ? 300 : 800"
        :canvas-height="$vuetify.breakpoint.xsOnly ? 150 : 400"
        :hold-object="container.hold"
        :custom-view-settings="{
          rulerType: !showRuler
            ? undefined
            : {
                lengthDim: length_dim,
                withSubdividers: false,
                withFullTexts: true,
                withText: true,
              },
        }">
        <template v-slot:annotations="slotProps">
          <annotations-component
            v-if="sceneRendered"
            :showAnnotationControls="!locked && !readonly"
            :annotations="container.hold.annotations"
            :uuid="container.hold.uuid"
            :sceneDiv="slotProps.sceneDiv"></annotations-component>
        </template>
      </scene-component>

      <v-container fluid v-if="!simpleMode">
        <v-row style="page-break-inside: avoid">
          <v-col>
            <scene-component
              enableReuse
              :key="String(showRuler)"
              :canvas-width="300"
              :canvas-height="150"
              :hold-object="container.hold"
              :custom-view-settings="{
                view: views.SIDE,
                rulerType: !showRuler
                  ? undefined
                  : {
                      lengthDim: length_dim,
                      withSubdividers: false,
                      withFullTexts: true,
                      withText: false,
                    },
              }"
              class="ma-2 justify-center"></scene-component>
          </v-col>
          <v-col>
            <scene-component
              enableReuse
              :key="String(showRuler)"
              :canvas-width="300"
              :canvas-height="150"
              :hold-object="container.hold"
              :custom-view-settings="{
                view: views.TOP,
                rulerType: !showRuler
                  ? undefined
                  : {
                      lengthDim: length_dim,
                      withSubdividers: false,
                      withFullTexts: true,
                      withText: false,
                    },
              }"
              class="ma-2"></scene-component>
          </v-col>
          <v-col>
            <scene-component
              enableReuse
              :key="String(showRuler)"
              :canvas-width="300"
              :canvas-height="150"
              :hold-object="container.hold"
              :custom-view-settings="{
                view: views.SIDE2,
                rulerType: !showRuler
                  ? undefined
                  : {
                      lengthDim: length_dim,
                      withSubdividers: false,
                      withFullTexts: true,
                      withText: false,
                    },
              }"
              class="ma-2"></scene-component>
          </v-col>
          <!-- <v-card class="ma-1">
            <v-card-title>Weight Distribution</v-card-title>
            <scene-component
              enableReuse
              :key="String(showRuler)"
              :canvas-width="300"
              :canvas-height="150"
              :hold-object="container.hold"
              :custom-view-settings="{
                view: views.THREED,
                highlight: 'weightScale',
                rulerType: !showRuler
                  ? undefined
                  : {
                      lengthDim: length_dim,
                      withSubdividers: false,
                      withFullTexts: true,
                      withText: false,
                    },
              }"
              class="ma-2"
            ></scene-component>
          </v-card> -->
        </v-row>
      </v-container>

      <v-card v-if="!simpleMode" style="page-break-inside: avoid">
        <v-card-text>
          <v-row dense style="white-space: nowrap">
            <v-col>
              <p class="text-h6">{{ container.hold.name }}</p>
              <table>
                <tbody>
                  <tr>
                    <td>Inside Length:</td>
                    <td>{{ container.hold.L | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Inside Width:</td>
                    <td>{{ container.hold.W | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Height from ground:</td>
                    <td>
                      {{
                        (container.hold.H +
                          (container.hold.floor_height || 0) +
                          (container.hold.clearence || 0))
                          | toLength
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Payload:</td>
                    <td>{{ container.payload | toWeight }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>

            <v-col>
              <p class="text-h6">Utilization</p>
              <table>
                <tbody>
                  <tr>
                    <td>Length:</td>
                    <td>{{ container.usedLength | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Width:</td>
                    <td>{{ container.usedWidth | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Height:</td>
                    <td>{{ container.usedHeight | toLength }}</td>
                  </tr>
                  <tr v-if="shipping_factor">
                    <td class="pr-2">Chargable WT:</td>
                    <td>
                      {{ container.chargableWeight(shipping_factor) | toWeight }}
                    </td>
                  </tr>
                  <tr>
                    <td>Net Wt:</td>
                    <td>
                      {{ container.weight | toWeight }} ({{
                        container.weightUtilization | percentage
                      }}
                      %)
                    </td>
                  </tr>

                  <tr>
                    <td>Volume:</td>
                    <td>
                      {{ container.volume | toVolume }}
                      <span v-if="container.volumeUtilization">
                        ({{ container.volumeUtilization | percentage }} %),
                        {{ container.freeVolume | toVolume }} free
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>

            <v-col>
              <p class="text-h6 oog-value">
                Total
                <v-icon color="orange" v-if="container.oogValues.length">mdi-information</v-icon>
              </p>
              <table>
                <tbody>
                  <tr>
                    <td>Length:</td>
                    <td>
                      {{ Math.max(container.usedLength, container.hold.L) | toLength }}
                    </td>
                  </tr>
                  <tr>
                    <td>Width:</td>
                    <td>
                      {{ Math.max(container.usedWidth, container.hold.W) | toLength }}
                    </td>
                  </tr>
                  <tr>
                    <td>Height:</td>
                    <td>
                      {{
                        (Math.max(container.usedHeight, container.hold.H) +
                          (container.hold.floor_height || 0) +
                          (container.hold.clearence || 0))
                          | toLength
                      }}
                    </td>
                  </tr>

                  <tr v-if="container.tare > 0">
                    <td>Gross Wt:</td>
                    <td>
                      {{ container.grossWeight | toWeight }}
                    </td>
                  </tr>
                  <tr v-for="oogValue in container.oogValues" :key="oogValue.title">
                    <td>{{ oogValue.title }}:</td>
                    <td v-if="oogValue.type === 'length'">
                      {{ oogValue.value | toLength }}
                    </td>
                    <td v-else-if="oogValue.type === 'weight'">
                      {{ oogValue.value | toWeight }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>

            <v-col v-if="container.axleWeights">
              <p v-if="container.tare" class="text-h6">Axle Weights</p>
              <p v-else class="text-h6">Axle Weights (Net)</p>
              <div v-for="axleSet in container.axleWeights" :key="axleSet.title">
                {{ axleSet.title }} : {{ axleSet.value | toWeight }}
                <v-icon v-if="axleSet.warning" color="red">mdi-alert</v-icon>
              </div>
            </v-col>
            <v-col v-else>
              <p class="text-h6">CoG {{ container.tare ? '' : '(Net)' }}</p>
              <table>
                <tbody>
                  <tr>
                    <td>X(L):</td>
                    <td>{{ container.cog.x | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Y(W):</td>
                    <td>{{ container.cog.y | toLength }}</td>
                  </tr>
                  <tr>
                    <td>Z(H):</td>
                    <td>{{ container.cog.z | toLength }}</td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="container.hold.notes">
        <v-alert class="container-notes" outlined color="info" icon="mdi-message-bulleted">{{
          container.hold.notes
        }}</v-alert>
      </div>
      <div v-if="showInstructions" :key="`instructions`" style="page-break-before: always">
        <loadplan-instructions :container="container" :loadingMode="showInstructions" />
        <div v-if="!simpleMode">
          <div v-for="(pallet, k) in uniquePallets" :key="k">
            <v-card class="mb-4 pt-4" style="page-break-before: always" outlined>
              <div class="text-h5 ml-4">
                {{ pallet.label }}
                <span v-if="pallet.count > 1">× {{ pallet.count }}</span>
              </div>
              <loadplan-instructions
                :container="pallet.container"
                :loadingMode="showInstructions" />
            </v-card>
          </div>
        </div>
      </div>

      <loadplan-table
        :container="container"
        :bundledItems="bundledItems"
        :columns="columns"
        :presentationSettings="presentationSettings"
        :lengthDims="lengthDimensions"
        :weightDims="weightDimensions" />
      <hr />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { AugmentedHold } from '@/models/augmented/hold';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import annotationsComponent from '@/components/Custom/Annotations.vue';
import { VIEWS } from '@/graphics/sceneManager';
import { Views } from '@/models/GraphicsModel';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CompanySettings } from '@/models/UserCompanyModel';
import LoadplanTable from './Table.vue';
import setDisplay from './SetDisplay.vue';
import loadplanInstructions from './Instructions.vue';
import { CargoLayer, HoldItem, Loadplan } from '@/models/LoadlistModel';
import itemUtils from '@/misc/itemUtils';

export default Vue.extend({
  name: 'loadplan-hold',
  components: {
    sceneComponent,
    LoadplanTable,
    setDisplay,
    loadplanInstructions,
    annotationsComponent,
  },
  props: {
    simpleMode: Boolean,
    showRuler: Boolean,
    showQuotations: Boolean,
    currencySymbol: String,
    shipping_factor: Number,
    holdQuotations: Number,
    lengthDimensions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    weightDimensions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    presentationSettings: {
      type: Object as PropType<CompanySettings>,
      default: () => {},
    },
    container: Object as PropType<AugmentedHold>,
    showInstructions: String,
    locked: Boolean,
  },
  data() {
    return {
      sceneRendered: false,
    };
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    views(): { [key: string]: Views } {
      return VIEWS;
    },
    bundledItems(): HoldItem[] {
      return itemUtils.bundledItems(this.container.cargoesInLayerOrder);
    },
    uniquePallets(): {
      container: AugmentedHold;
      count: number;
      label: string;
    }[] {
      const distinct: {
        [key: string]: {
          container: AugmentedHold;
          count: number;
          label: string;
        };
      } = {};

      this.container.cargoesInLayerOrder
        .filter((data) => !!data.from_container && !data.bundling?.is_bundle)
        .map((item) => {
          return { pallet: item.from_container, label: item.label };
        })
        .forEach((data) => {
          if (!distinct[data.pallet.uuid]) {
            distinct[data.pallet.uuid] = {
              container: new AugmentedHold(data.pallet),
              count: 1,
              label: data.label,
            };
          } else {
            distinct[data.pallet.uuid].count += 1;
          }
        });
      return Object.entries(distinct).map((entry) => entry[1]);
    },
    length_dim(): string {
      return this.miscStore.length_dim;
    },
    weight_dim(): string {
      return this.miscStore.weight_dim;
    },
    readonly(): boolean {
      return this.loadlistStore.readonly;
    },
  },

  methods: {},
});
</script>

<style scoped></style>
