<template>
  <div>
    <v-card>
      <v-card-text class="pt-6" v-if="!simpleMode">
        <v-alert type="info" outlined>
          Presets has predefined equipment and load settings that you can use for your calculations.
          <br />The one marked with a <v-icon color="primary ">mdi-star</v-icon> is the default one
          - each new load plan created will be pre filled with the content of this preset
        </v-alert>
      </v-card-text>

      <v-toolbar dense flat class="align-start px-2" v-if="!simpleMode">
        <v-tooltip bottom :disabled="user.is_editor">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                :disabled="!user.is_editor"
                @click="showAddDialog = true"
                color="success"
                :ripple="false">
                <v-icon>mdi-plus-circle</v-icon>
                Create preset
              </v-btn>
            </div>
          </template>
          <span>You need to be a Editor to perform this action</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable></v-text-field>
      </v-toolbar>
      <!-- TODO Extract to separate component -->
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="configurations"
          :loading="loading"
          :items-per-page="10"
          :server-items-length="totalConfigurations"
          :options.sync="pagination"
          :search="search"
          show-expand
          single-expand
          no-data-text="No load presets found"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageOptions: [10],
          }"
          @item-expanded="selectedConfiguration = JSON.parse(JSON.stringify($event.item))">
          <template v-slot:item="{ item, isExpanded, expand }">
            <tr>
              <td>
                <v-btn icon color="primary" @click="expand(!isExpanded)">
                  <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
              <td
                :class="{
                  'font-weight-bold': loadplan && loadplan.preset === item.id,
                }">
                <span
                  v-if="
                    defaultConfiguration === item.id || (!defaultConfiguration && item.default)
                  ">
                </span>

                <v-icon v-if="!item.default">mdi-account</v-icon>

                {{ item.name }}
                <v-icon
                  color="primary "
                  v-if="defaultConfiguration === item.id || (!defaultConfiguration && item.default)"
                  >mdi-star</v-icon
                >
              </td>
              <td class="text-right">
                <v-tooltip top v-if="showUsePreset">
                  <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on" @click.stop="usePreset(item)">
                      <v-icon color="success">mdi-import</v-icon>
                    </v-btn>
                  </template>
                  <span>Use this preset</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class :colspan="headers.length">
              <v-card flat>
                <v-card-text>
                  <v-card>
                    <v-card-text v-if="selectedConfiguration">
                      <v-form
                        ref="form"
                        v-model="validForm"
                        :disabled="item.default || !user.is_editor || simpleMode">
                        <v-text-field
                          v-model="selectedConfiguration.name"
                          label="Name"
                          :rules="nameRules"
                          required
                          hint="The name of this preset"></v-text-field>
                        <v-textarea
                          rows="2"
                          label="Description"
                          hint="Add a description for this preset"
                          v-model="selectedConfiguration.description"></v-textarea>
                      </v-form>
                    </v-card-text>
                    <v-card-actions v-if="!simpleMode">
                      <v-btn
                        class="primary"
                        :disabled="!isAuthenticated || defaultConfiguration == item.id"
                        @click="setDefault(item.id)">
                        <v-icon>mdi-star</v-icon> Set as default
                      </v-btn>

                      <v-btn
                        :disabled="!validForm || loading || item.default || !user.is_editor"
                        @click="updateConfiguration"
                        ><v-icon>mdi-content-save</v-icon> Save</v-btn
                      >
                      <v-spacer></v-spacer>

                      <v-btn
                        :disabled="item.default || !user.is_editor"
                        class="error"
                        @click="showDeleteDialog = true">
                        <v-icon>mdi-delete</v-icon> Delete
                      </v-btn>
                    </v-card-actions>
                    <v-card-text>
                      <v-row class="mt-2">
                        <v-col class="d-flex flex-column" v-for="s in presetLists" :key="s.value">
                          <v-card class="flex d-flex flex-column">
                            <v-card-title
                              >{{ s.title | snakeToTitleCase }}s
                              <v-spacer></v-spacer>
                              <v-btn
                                icon
                                color="primary"
                                v-if="!simpleMode"
                                :disabled="item.default || !user.is_editor"
                                @click="
                                  holdAndSetsPicker = s.value;
                                  showHoldsAndSetsPicker = true;
                                ">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <div
                                v-for="o in item.data[s.value]"
                                :key="o.id"
                                class="preset-column">
                                <p class="text-center mb-0 mt-1 font-weight-bold">
                                  {{ o.name }}
                                </p>

                                <scene-component
                                  v-if="s.value !== 'set_types'"
                                  :hold-object="o"
                                  :canvas-width="200"
                                  :canvas-height="100"></scene-component>
                                <scene-component
                                  v-else
                                  :setType="o"
                                  :canvas-width="200"
                                  :canvas-height="100"></scene-component>
                              </div>
                              <p
                                class="font-italic"
                                v-if="!item.data[s.value] || !item.data[s.value].length">
                                Empty
                              </p>
                            </v-card-text>
                            <v-spacer />

                            <!-- <v-card-actions v-if="!simpleMode">
                              <v-btn
                                :disabled="item.default || !user.is_editor"
                                block
                                @click="
                                  holdAndSetsPicker = s.value;
                                  showHoldsAndSetsPicker = true;
                                "
                              >
                                <v-icon left>mdi-pencil</v-icon>
                                Edit
                              </v-btn>
                            </v-card-actions> -->
                          </v-card>
                        </v-col>

                        <v-col class="d-flex flex-column">
                          <v-card class="flex d-flex flex-column">
                            <v-card-title
                              >Settings<v-spacer></v-spacer>
                              <v-btn
                                icon
                                color="primary"
                                v-if="!simpleMode"
                                :disabled="item.default || !user.is_editor"
                                @click="showSettingsDialog = true">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <div v-if="item.data.settings">
                                <div v-for="(value, key) in item.data.settings" :key="key">
                                  <p v-if="!!value">
                                    <span v-if="value !== Object(value)">
                                      {{ key | snakeToTitleCase }}: {{ value }}
                                    </span>
                                    <span v-else>
                                      {{ key | snakeToTitleCase }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </v-card-text>
                            <v-spacer />
                            <!-- <v-card-actions v-if="!simpleMode">
                              <v-btn
                                :disabled="item.default || !user.is_editor"
                                block
                                @click="showSettingsDialog = true"
                              >
                                <v-icon left>mdi-pencil</v-icon>
                                Edit
                              </v-btn>
                            </v-card-actions> -->
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </td>

            <v-dialog
              v-model="showHoldsAndSetsPicker"
              v-if="showHoldsAndSetsPicker"
              scrollable
              width="800">
              <v-card>
                <v-card-title class="text-h5">Select {{ holdAndSetsPickerTitle }}s</v-card-title>
                <v-card-text>
                  <v-subheader>Selected</v-subheader>
                  <v-data-table
                    :items="item.data[holdAndSetsPicker] || []"
                    :hideDefaultHeader="true"
                    hide-default-footer
                    show-expand
                    single-expand
                    class="elevation-2 mt-4"
                    :headers="[
                      {
                        value: 'name',
                        align: 'left',
                      },
                      {
                        value: 'actions',
                        align: 'right',
                      },
                    ]">
                    <template #[`item.actions`]="{ item: container }">
                      <v-icon
                        color="red"
                        class="mr-2"
                        @click.stop="removeFromPreset(holdAndSetsPicker, container)">
                        mdi-minus-circle
                      </v-icon>
                    </template>
                    <template v-slot:expanded-item="{ headers, item: container }">
                      <td :colspan="headers.length">
                        <div class="pa-4">
                          <inline-hold-edit-component
                            v-if="holdAndSetsPicker !== 'set_types'"
                            v-model="
                              item.data[holdAndSetsPicker][
                                indexOfSelected(holdAndSetsPicker, container.id)
                              ]
                            "
                            :basetype="holdAndSetsPicker === 'pallet_types' ? 'PALL' : list_type"
                            :showSubHoldProps="holdAndSetsPicker === 'pallet_types'"
                            :key="container.id">
                            <template v-slot:view="{ renderKey }">
                              <scene-component
                                :hold-object="container"
                                :canvas-width="400"
                                :canvas-height="200"
                                :key="renderKey"></scene-component>
                            </template>
                          </inline-hold-edit-component>
                          <inline-set-edit-component
                            v-else
                            v-model="
                              item.data[holdAndSetsPicker][
                                indexOfSelected(holdAndSetsPicker, container.id)
                              ]
                            "
                            :basetype="container.base_type">
                            <template v-slot:view="{ renderKey }">
                              <scene-component
                                :setType="container"
                                :canvas-width="300"
                                :canvas-height="150"
                                :key="renderKey"
                                :customViewSettings="{
                                  view: '3d-ortho',
                                  containerNumbers: true,
                                }"></scene-component>
                            </template>
                          </inline-set-edit-component>
                          <v-btn
                            class="mt-4"
                            @click.stop="
                              selectedConfiguration.data = item.data;
                              updateConfiguration();
                            "
                            :ripple="false"
                            ><v-icon>mdi-content-save</v-icon>Save</v-btn
                          >
                        </div>
                      </td>
                    </template>
                  </v-data-table>

                  <container-library-component
                    v-if="holdAndSetsPicker !== 'set_types'"
                    :holdType="holdAndSetsPicker === 'pallet_types' ? 'PALL' : list_type">
                    <template v-slot:items="{ paginatedItems: containers }">
                      <v-data-table
                        :items="containers"
                        :hideDefaultHeader="true"
                        hide-default-footer
                        show-expand
                        single-expand
                        class="elevation-2 mt-4"
                        :headers="[
                          {
                            text: '',
                            value: 'name',
                          },
                          {
                            text: 'Actions',
                            value: 'actions',
                            sortable: false,
                            align: 'right',
                          },
                        ]">
                        <template #[`item.actions`]="{ item: container }">
                          <v-icon
                            color="green"
                            class="mr-2"
                            @click.stop="addToPreset(holdAndSetsPicker, container.data)"
                            :disabled="isAddDisabled(item.data, container.data.id)">
                            mdi-plus-circle
                          </v-icon>
                        </template>
                        <template v-slot:expanded-item="{ headers, item: container }">
                          <td :colspan="headers.length">
                            <scene-component
                              :hold-object="container.data"
                              :canvas-width="300"
                              :canvas-height="150"></scene-component>
                          </td>
                        </template>
                      </v-data-table>
                    </template>
                  </container-library-component>
                  <sets-library-component v-else :setType="list_type">
                    <template v-slot:items="{ paginatedItems: sets }">
                      <v-data-table
                        :items="sets"
                        :hideDefaultHeader="true"
                        hide-default-footer
                        show-expand
                        single-expand
                        class="elevation-2 mt-4"
                        :headers="[
                          {
                            text: '',
                            value: 'name',
                          },
                          {
                            text: 'Actions',
                            value: 'actions',
                            sortable: false,
                            align: 'right',
                          },
                        ]">
                        <template #[`item.actions`]="{ item: set }">
                          <v-icon
                            color="green"
                            class="mr-2"
                            @click.stop="addToPreset(holdAndSetsPicker, set.data)"
                            :disabled="isAddDisabled(item.data, set.id)">
                            mdi-plus-circle
                          </v-icon>
                        </template>
                        <template v-slot:expanded-item="{ headers, item: set }">
                          <td :colspan="headers.length">
                            <scene-component
                              :setType="set.data"
                              :canvas-width="300"
                              :canvas-height="150"></scene-component>
                          </td>
                        </template>
                      </v-data-table>
                    </template>
                  </sets-library-component>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click.stop="showHoldsAndSetsPicker = false" :ripple="false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <load-settings-modal
              v-if="selectedConfiguration"
              :visible.sync="showSettingsDialog"
              :base_type="list_type"
              :holds="allHolds"
              :settings="selectedConfiguration.data.settings"
              :items="cargoes"
              @update="updateSettings"></load-settings-modal>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showDeleteDialog" width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete preset?</span>
        </v-card-title>
        <v-card-text>Do you really want to delete this load preset?</v-card-text>
        <v-card-actions>
          <v-btn text @click="showDeleteDialog = false">Cancel</v-btn>
          <v-spacer />
          <v-btn class="mr-2" color="error" :disabled="loading" @click="deleteConfiguration"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddDialog" width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create load preset</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm">
            <v-text-field
              v-model="newConfiguration.name"
              label="Name"
              :rules="nameRules"
              required
              hint="The name of this configuration"></v-text-field>
            <v-textarea
              label="Description"
              hint="Add a description for this configuration"
              v-model="newConfiguration.description"></v-textarea>
            <v-checkbox
              v-if="loadplan"
              v-model="newConfiguration.from_current_loadplan"
              label="Create from current load plan"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showAddDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="createConfiguration"
            :loading="loading"
            :disabled="!validForm || loading"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :timeout="5000" v-model="showSnackbar" top>
      <span>{{ snackbarInfo }}</span>

      <v-btn text color="primary" @click.native="showSnackbar = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import API from '@/API';
import sceneComponent from '@/components/Custom/SceneComponent.vue';
import containerLibraryComponent from '@/components/Custom/ContainerLibrary.vue';
import loadSettingsModal from '@/components/Modals/LoadSettings/LoadSettings.vue';
import { DEFAULT_SETTINGS } from '@/components/Modals/LoadSettings/LoadSettings.vue';
import {
  HoldData,
  ListType,
  LoadConfigurationData,
  LoadConfiguration,
  Loadlist,
  Loadplan,
  HoldInputItem,
} from '@/models/LoadlistModel';
import { UserPreferences, User } from '@/models/UserCompanyModel';
import { useLoadlistStore } from '@/stores/loadlistStore';
import { mapStores } from 'pinia';
import { useMiscStore } from '@/stores/miscStore';
import { CalculationSettings } from '@/models/CalculationModel';
import InlineHoldEditComponent from '@/components/Custom/InlineHoldEdit.vue';
import setsLibraryComponent from '@/components/Custom/SetsLibrary.vue';
import { SetType, SetTypeData } from '@/models/SetsModel';
import InlineSetEditComponent from '@/components/Custom/InlineSetEdit.vue';
import { getSerializerError } from '@/misc/errorUtils';

interface SelectedConfiguration {
  [listType: string]: number;
}

export default Vue.extend({
  name: 'load-configurations-modal',
  components: {
    sceneComponent,
    containerLibraryComponent,
    loadSettingsModal,
    InlineHoldEditComponent,
    setsLibraryComponent,
    InlineSetEditComponent,
  },
  props: {
    visible: {
      type: Boolean,
    },
    list_type: {
      type: String as PropType<ListType>,
      default: null as ListType,
    },
    showUsePreset: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useMiscStore, useLoadlistStore),
    show: {
      get(): boolean {
        return this.visible;
      },
      set(value: boolean): void {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    typeName(): string {
      return this.$typeNames(this.list_type);
    },
    defaultConfiguration(): number | null {
      return this.preferences?.default_presets?.[this.list_type];
    },
    loadlist(): Loadlist {
      return this.loadlistStore.loadlist;
    },
    loadplan(): Loadplan {
      return this.loadlistStore.loadplan;
    },
    preferences(): UserPreferences {
      return this.miscStore.preferences;
    },
    isAuthenticated(): boolean {
      return this.miscStore.is_authenticated;
    },
    user(): User {
      return this.miscStore.user;
    },
    presetLists(): any[] {
      return [
        { value: 'holds', title: this.typeName },
        { value: 'pallet_types', title: 'pallet' },
        ...(this.displaySets
          ? [{ value: 'set_types', title: this.$setNames(this.list_type) }]
          : []),
      ];
    },
    holdAndSetsPickerTitle(): string {
      switch (this.holdAndSetsPicker) {
        case 'set_types':
          return 'set';
        case 'pallet_types':
          return 'pallet';
        default:
          return this.typeName;
      }
    },
    allHolds(): HoldData[] {
      return (this.selectedConfiguration.data.holds || [])
        .concat(this.selectedConfiguration.data.pallet_types || [])
        .concat(
          (this.selectedConfiguration.data.set_types || []).flatMap((st) => st.container_types)
        );
    },
    displaySets(): boolean {
      return this.miscStore.company.sets_enabled;
    },
    cargoes(): HoldInputItem[] {
      return this.miscStore.cargoes.map((i) => i.data);
    },
  },
  data() {
    return {
      loading: false,
      showSettingsDialog: false,
      showDeleteDialog: false,
      showAddDialog: false,
      showSnackbar: false,
      showHoldsAndSetsPicker: false,
      holdAndSetsPicker: null,
      snackbarInfo: null,
      headers: [
        { text: '', value: 'name', align: 'left', sortable: false },
        { text: '', value: 'actions', align: 'right', sortable: false },
      ],
      configurations: [] as LoadConfiguration[],
      totalConfigurations: 0,
      selectedConfiguration: null as LoadConfiguration,
      pagination: {
        page: 1,
      },
      search: '',
      newConfiguration: {
        name: '',
        description: '',
        from_current_loadplan: false,
      },
      validForm: false,
      nameRules: [
        (v: string) => !!v || 'Name is required',
        (v: string) => (v && v.length <= 200) || 'Name must be less than 200 characters',
      ],
    };
  },
  watch: {
    pagination: {
      handler(): void {
        this.getConfigurations();
      },
      deep: true,
    },
    list_type(): void {
      this.getConfigurations();
    },
    search(): void {
      this.getConfigurations();
    },
  },
  mounted(): void {
    this.getConfigurations();
  },
  methods: {
    isAddDisabled(data: any, id: any): boolean {
      return (
        data[this.holdAndSetsPicker] && data[this.holdAndSetsPicker].some((h: any) => h.id == id)
      );
    },
    addToPreset(key: keyof LoadConfigurationData, value: HoldData | SetTypeData): void {
      if (this.selectedConfiguration.data[key] == null) {
        this.selectedConfiguration.data[key] = [];
      }

      (this.selectedConfiguration.data[key] as any[]).push(JSON.parse(JSON.stringify(value)));
      this.updateConfiguration();
    },
    removeFromPreset(key: keyof LoadConfigurationData, value: HoldData | SetTypeData): void {
      this.selectedConfiguration.data[key] = (this.selectedConfiguration.data[key] as any[]).filter(
        (h: HoldData) => h.id !== value.id
      );
      this.updateConfiguration();
    },

    updateSettings(newSettings: CalculationSettings): void {
      this.selectedConfiguration.data.settings = newSettings;
      this.updateConfiguration();
    },
    updateConfiguration(): void {
      this.loading = true;
      API.updateLoadConfiguration({
        ...this.selectedConfiguration,
      })
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showDeleteDialog = false;
          this.snackbarInfo = 'Preset updated';
          this.showSnackbar = true;
        })
        .catch((error: any) => {
          this.loading = false;
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = 'Unknown error when saving';
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
    getConfigurations(): void {
      API.getLoadConfigurations({
        page: this.pagination.page,
        name: this.search,
        base_type: this.list_type,
      })
        .then((response) => {
          const count: number = response.data.count;
          const results: LoadConfiguration[] = response.data.results;
          this.totalConfigurations = count;
          this.configurations = results;
        })
        .catch((e: any) => {});
    },
    deleteConfiguration(): void {
      this.loading = true;
      this.pagination.page = 1;
      API.deleteLoadConfiguration(this.selectedConfiguration.id)
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showDeleteDialog = false;
          this.selectedConfiguration = null;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    createConfiguration(): void {
      this.loading = true;
      API.createLoadConfiguration({
        ...this.newConfiguration,
        base_type: this.list_type,
        data: {
          holds: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.selected_holds || null
            : null,
          pallet_types: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.pallet_types || null
            : null,
          set_types: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.set_types || null
            : null,
          settings: this.newConfiguration.from_current_loadplan
            ? this.loadplan?.settings || DEFAULT_SETTINGS
            : DEFAULT_SETTINGS,
        },
      })
        .then((response) => {
          this.loading = false;
          this.getConfigurations();
          this.showAddDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error?.response?.data) {
            this.snackbarInfo = getSerializerError(error.response.data);
          } else {
            this.snackbarInfo = 'Unknown error when saving';
            console.log(error);
          }
          this.showSnackbar = true;
        });
    },
    setDefault(id: number): void {
      const default_presets: SelectedConfiguration = JSON.parse(
        JSON.stringify(this.preferences['default_presets'] || {})
      );
      default_presets[this.list_type] = id;
      this.updateMe(default_presets).then((response) => {
        this.showSnackbar = true;
        this.snackbarInfo = 'Default preset updated';
      });
    },
    updateMe(default_presets: SelectedConfiguration): Promise<undefined> {
      return this.miscStore.updateMe({
        preferences: {
          default_presets: default_presets,
        },
      });
    },
    usePreset(preset: LoadConfiguration): void {
      this.$emit('selectedPreset', preset);
      if (this.loadplan) this.loadlistStore.useLoadConfiguration(preset);
      this.show = false;
    },
    indexOfSelected(preset_type: keyof LoadConfigurationData, id: number): number {
      return (this.selectedConfiguration.data[preset_type] as any[])?.findIndex((i) => i.id === id);
    },
  },
});
</script>

<style scoped>
.v-data-table {
  margin-top: -20px;
}
.preset-column {
  width: 200px;
}

>>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
